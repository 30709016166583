import Cookies from "js-cookie";
import { API } from "../App";

// const token = Cookies.get("fmljwt")
// Affiliation Dashboard

export const getDashboardData = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/dashBoardCount", {}, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getServiceSentereCount = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/cityWiseCount", {}, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getVehicleDetailCount = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/vehicleDetailCount", {}, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getNewsCategoriesCount = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/newsCategoriesCount", {}, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffiliationDashboardDropdown = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get("/affilation_dashboard", { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffiliationDashboardData = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/affilation_dashboard_data", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const affilationDashboardStatusUpdate = async (status, id) => {
    const token = Cookies.get("fmljwt")

    let statusCode;
    status ? statusCode = 1 : statusCode = 0
    try {
        const Form = new FormData();
        Form.append("id", id)
        Form.append("status", statusCode)
        const result = await API.post("/affilation_dashboard_update_status", Form, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

// Affiliation Program

export const changeDatabase = async (data) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/database_update", data, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffiliatiPlace = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get("/affilation", { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffiliationProgramData = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/pagination_affiliate", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const affilationProgramView = async (id) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/affilation_data_show', id, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const updatePositionAffilation = async (id, GroupId, service) => {
    const token = Cookies.get("fmljwt")

    try {
        const Formdata = new FormData()
        Formdata.append('position_data', JSON.stringify(id));
        Formdata.append('group_id', GroupId);
        if (service) {
            Formdata.append('service', service);
        }
        const result = await API.post('/update_position_affilation', Formdata, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const updateGroupPositionAffilation = async (id, GroupId, selectedCategory) => {
    const token = Cookies.get("fmljwt")

    try {
        const Formdata = new FormData()
        Formdata.append('place_id', id);
        Formdata.append('group_position_data', JSON.stringify(GroupId));
        selectedCategory && Formdata.append('category_id', selectedCategory);
        const result = await API.post('/update_group_position', Formdata, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const affilationProgramStatusUpdate = async (status, id, key) => {
    const token = Cookies.get("fmljwt")

    let statusCode;
    status ? statusCode = 1 : statusCode = 0
    try {
        const Form = new FormData();
        Form.append("id", id)
        Form.append("status", statusCode)
        Form.append("key_name", key)
        const result = await API.post("/update_app_status", Form, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteAffilationProgramPlace = async (params) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.delete(`/delete_affilation/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getLanguageImage = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/get_language_image`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const updateAffilationLink = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post("/replace-url", payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
/**insurance */
export const getInsuranceServices = async (formData) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/get_insurance_services',formData, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffilationInsurance = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/get_affiliation_insurance', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

export const getMakeModalDropDown = async (service_id) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post(`/get_vehicle_models_makers`, { service_id }, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }
}
export const AddAffilationInsurance = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/add_affiliation_insurance', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
// offer 
export const getAffilationOffer = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get('/offer_data', { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const addAffilationOffer = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/add_offer', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

// Add Affilation data
export const getDynamicDropdown = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get('/dynamic_dropdown', { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const editDynamicDropdown = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/edit_dynamic_dropdown', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

// Add New Affilation 
export const getAffilationDropdown = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get('/get_affilation_dropdown', { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffilationData = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/get_affilation_data', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const AddAffilationData = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/add_affilation', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

//language apis
export const getLanguage = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get('/get_language', { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const saveLanguage = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/store_language_lable', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteLanguage = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/delete_language_lable', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

//services city api

export const getAffilationState = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get('/affilation_state', { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAffilationCityData = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post('/affilation_city_data', payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

//services view  city api

export const viewAffilationCity = async (params) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get(`/get_affilation_city/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const addAffilationCity = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/add_affilation_city`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteAffilationCity = async (params) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.delete(`/delete_affilation_city/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};


// services provider city

export const getServiceProviderCity = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/get_service_provider_city`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const viewServiceProviderCity = async (params) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get(`/view_service_provider_city/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getServiceProviderDropdown = async () => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get(`/get_service_provider_dropdown`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const addServiceProviderCity = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/add_service_provider_city`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteServiceProviderCity = async (params) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.delete(`/delete_service_provider_city/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getServiceCityIdWisePincode = async (cityId, providerId) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get(`/get_service_city_id_wise_pincode?city_id=${cityId}&service_provider_id=${providerId}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const updatePincodeStatus = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/update_pincode_status`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteServiceCity = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/delete_service_city`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};




// Add all images

export const getAllLanguageImage = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.get(`/get_all_language_image`, {}, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const saveImages = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/store_language_image_new_detail`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const getAdType = async (payload) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/get_adtype`, { payload }, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};
export const deleteLanguageImage = async (id) => {
    const token = Cookies.get("fmljwt")

    try {
        const result = await API.post(`/delete_language_image/${id}`, {}, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};


// active challan

export const getActiveChallanState = async (payload) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post(`/get_active_challan_state`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};


export const addServiceProviderChallanState = async (payload) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.post(`/add_service_provider_challan_state`, payload, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

export const viewChallanState = async (params) => {
    const token = Cookies.get("fmljwt");
    try {
        const result = await API.get(`/view_challan_state/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};

export const deleteChallanState = async (params) => {
    const token = Cookies.get("fmljwt")
    try {
        const result = await API.delete(`/delete_challan_state/${params}`, { headers: { authorization: `${token}` } })
        return result
    } catch (error) {
        console.log(error)
    }

};