import { DotLottiePlayer } from '@dotlottie/react-player';
import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SelectPicker } from 'rsuite';

const AffiliationImageModal = ({ setImageShow,imageshow, ImagehandleClose, languageImage, adType, handleSelectImage, selectedModalImage, setSelectedModalImage }) => {
      const [selectedLanguage, setSelectedLanguage] = useState("en")

      const languages = ["en", "hi", "mr", "gu", "kn", "ta", "te", "bn", "ml", "or", "pa", "rj"];

      const language = []
      languages?.map((item) =>
            language.push({ label: item, value: item })
      );

      
      return (
            <Modal show={imageshow} className="affiliationmodal" size="lg" onHide={ImagehandleClose}>
                  <Modal.Header>
                        <Modal.Title>Affiliation Image - <span className="text-primary">{adType?.find((el) => el.value == languageImage?.adtype_id)?.label?.charAt(0).toUpperCase() + adType.find((el) => el.value == languageImage?.adtype_id)?.label?.slice(1)}</span></Modal.Title>
                        <div className="d-flex align-items-center">
                              <SelectPicker
                                    cleanable={false}
                                    data={language}
                                    value={selectedLanguage}
                                    placement="bottomEnd"
                                    onChange={(e) => setSelectedLanguage(e)}
                                    block
                                    size="sm"
                                    placeholder="Select Lable"
                              />
                              <button type="button" className="btn-close ms-3 me-1" aria-label="Close" onClick={() => ImagehandleClose()}></button>
                        </div>
                  </Modal.Header>
                  <Modal.Body>
                        {/* ---- ANIM_2  and WEB_ANIM_2 ---*/}
                        {["ANIM_2", "WEB_ANIM_2", "ICON_2", "WEB_ICON"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        ) && <div className="anim_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };

                                                return (<Col md={2}>
                                                      <a href="#" onClick={handleImageClick} className="position-relative">
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="anim_2_content">
                                                                  {(item?.image1?.split(".")[item?.image1?.split(".").length - 1] == "json") ? <DotLottiePlayer src={item?.image1} autoplay loop className="lottie-player"></DotLottiePlayer>
                                                                        : <img src={item?.image1} alt="" title="" className="img-fluid" />}
                                                                  <p style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</p>
                                                            </div>
                                                      </a>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}
                        {/* ---- BANNER_2  and WEB_BANNER_2 ---*/}

                        {["BANNER_2", "WEB_BANNER_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="banner_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={12}>
                                                      <div onClick={handleImageClick} className="banner_2_content" style={{ borderColor: item?.background_border_color, background: item?.background_color }}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <img src={item?.image1} alt="" title="" className="img-fluid" />
                                                            <p style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</p>
                                                            <a href="" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                  {JSON.parse(item?.button_text)[selectedLanguage]}
                                                            </a>
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}
                        {/* ---- BG_BANNER_2  and WEB_BG_BANNER_2 ---*/}
                        {["BG_BANNER_2", "WEB_BG_BANNER_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="bg_banner_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<>{item[selectedLanguage] != "" ?
                                                      <Col md={10}>
                                                            <div onClick={handleImageClick} className="bb2 banner_bg" style={{ backgroundImage: `url(${item[selectedLanguage]})` }}>
                                                                  <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                                  <div className="bb2_content">
                                                                        <p style={{ color: item?.headline_text_color }}>28 August, 2025</p>
                                                                        <span>Insurance Valid UpTo</span>
                                                                  </div>
                                                                  <div className="bb2_media">
                                                                        <img src={item?.image1} alt="" className="img-fluid" />
                                                                  </div>
                                                                  <div className="bb2_action">
                                                                        <button className="btn btn-primary btn-sm" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                              {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                        </button>
                                                                  </div>
                                                                  <img src={item?.image2} className="bb2_servicelogo" alt="" />
                                                            </div>
                                                      </Col> :
                                                      <Col md={10}>
                                                            <div onClick={handleImageClick} className="bb2" style={{ borderColor: item?.background_border_color, background: item?.background_color }} >
                                                                  <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                                  <div className="bb2_content">
                                                                        <p style={{ color: item?.headline_text_color }}>28 August, 2025</p>
                                                                        <span>Insurance Valid UpTo</span>
                                                                  </div>
                                                                  <div className="bb2_media">
                                                                        <img src={item?.image1} alt="" className="img-fluid" />
                                                                  </div>
                                                                  <div className="bb2_action">
                                                                        <button className="btn btn-primary btn-sm" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                              {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                        </button>
                                                                  </div>
                                                            </div>
                                                      </Col>}
                                                </>)
                                          })}
                                    </Row>
                              </div>}
                        {/* ---- Challan_Banner_2  and WEB_Challan_Banner_2 ---*/}
                        {["Challan_Banner_2", "WEB_Challan_Banner_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="challan_banner_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<><Col md={10}>
                                                      <div onClick={handleImageClick} className="cb_2 cb_2_service" style={{ borderColor: item?.background_border_color, background: item?.background_color }}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="cb_2_content">
                                                                  <h6 style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</h6>
                                                                  <p style={{ color: item?.paragraph_color }}>{JSON.parse(item?.paragraph_text)[selectedLanguage]}</p>
                                                            </div>
                                                            <div className="cb_2_media">
                                                                  <img src={item?.image1} alt="" className="img-fluid" />
                                                            </div>
                                                            <div className="cb_2_action">
                                                                  <button className="btn btn-primary btn-sm" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                        {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                  </button>
                                                            </div>
                                                            <img src={item?.image1} alt="" className="cb_2_service_icon" />
                                                      </div>
                                                </Col></>)
                                          })}
                                    </Row>
                              </div>}
                        {/* ---- ICON_CONTENT_2 ---*/}
                        {["RC_CONTENT_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="icon_content_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={10}>
                                                      <div onClick={handleImageClick} className="ic_2_card" style={{ borderColor: item?.background_border_color, background: item?.background_color }}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="ic_2_cardbody">
                                                                  <h6 style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</h6>
                                                                  <p style={{ color: item?.paragraph_color }}>{JSON.parse(item?.paragraph_text)[selectedLanguage]}</p>
                                                                  <a href="" className="btn btn-primary btn-sm" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                        {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                  </a>                                                </div>
                                                            <img src={item?.image1} alt="" className="img-fluid" />
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}
                        {/* ---- WEB_ICON_CONTENT_2 ---*/}
                        {["WEB_ICON_CONTENT_2", "ICON_CONTENT_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="web_icon_content_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={10}>
                                                      <div onClick={handleImageClick} className="wic_2_card" style={{ borderColor: item?.background_border_color, background: item?.background_color }}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="wic_2_cardheader">
                                                                  <img src={item?.image1} alt="" className="img-fluid" />
                                                                  <div className="wic_2_cardbody">
                                                                        <div>
                                                                              <h6 style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</h6>

                                                                              <p>Expires on 28 July, 2022</p>
                                                                        </div>
                                                                        <a href="" className="btn btn-primary btn-sm" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                              {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                        </a>
                                                                  </div>
                                                            </div>
                                                            <div className="wic_2_cardfooter">
                                                                  <span style={{ color: item?.paragraph_color }}>{JSON.parse(item?.paragraph_text)[selectedLanguage]}</span>
                                                            </div>
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}

                        {/* ---- WEB_OFFER_BUTTON_2 ---*/}
                        {["WEB_OFFER_BUTTON_2", "OFFER_BUTTON_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="web_offer_button_2">

                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={10}>
                                                      <div className="wob_2_card" style={{ borderColor: item?.background_border_color, background: item?.background_color }} onClick={handleImageClick}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="wob_2_cardbody">
                                                                  <h5 style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</h5>
                                                                  <p style={{ color: item?.paragraph_color }}>{JSON.parse(item?.paragraph_text)[selectedLanguage]}</p>
                                                                  <a href={item?.url} className="btn btn-primary btn-sm" style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                        {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                  </a>
                                                            </div>
                                                            <img src={item?.image1} alt="" className="ms-3" />
                                                            <img src={item?.image2} alt="" className="wob_2_logo" />
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}

                        {/* ---- Notification_2 ---*/}
                        {["Notification_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="notification_2">

                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={10}>
                                                      <div className="no_2_card" style={{ borderColor: item?.background_border_color, background: item?.background_color }} onClick={handleImageClick}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="no_2_cardbody">
                                                                  <h5 style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}</h5>
                                                                  <p style={{ color: item?.paragraph_color }}>{JSON.parse(item?.paragraph_text)[selectedLanguage]}</p>
                                                                  <a href={item?.url} style={{ color: item?.button_text_color, borderColor: item?.button_border_color, background: item?.button_color }}>
                                                                        {JSON.parse(item?.button_text)[selectedLanguage]}
                                                                  </a>
                                                            </div>
                                                            <img src={item?.image1} alt="" className="ms-3 no_2_img" />
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}

                        {/* ---- SQUARE_POST_2 ---*/}
                        {["SQUARE_POST_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="square_post_2">

                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={4}>
                                                      <div className="sp_2_card" onClick={handleImageClick}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            {
                                                                  (item?.image1 && typeof item.image1 === 'string' && ['mov', 'mp4'].includes(item.image1.split('.').pop())) ?
                                                                        <video controls>
                                                                              <source src={item?.image1} type="video/mp4" />
                                                                              Your browser does not support the video tag.
                                                                        </video> :
                                                                        <img src={item?.image1} alt="" className="img-fluid" />
                                                            }
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}

                        {/* ---- DIALOG_BANNER_2 ---*/}
                        {["DIALOG_BANNER_2"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="dialog_banner_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md={10}>
                                                      <div onClick={handleImageClick} className="db_2_card" style={{ borderColor: item?.background_border_color, background: item?.background_color }}>
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <div className="db_2_cardheader">
                                                                  <img src={item?.image1} alt="" className="db_2_media img-fluid" />
                                                                  <div className="db_2_cardbody">
                                                                        <div>
                                                                              <h6 style={{ color: item?.headline_text_color }}>{JSON.parse(item?.headline_text)[selectedLanguage]}<img src={item?.image2} alt="" className="img-fluid" /></h6>
                                                                              <p>Expires on 28 July, 2022</p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="db_2_cardfooter">
                                                                  <span style={{ color: item?.paragraph_color }}>{JSON.parse(item?.paragraph_text)[selectedLanguage]}</span>
                                                            </div>
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}

                        {/* ---- All Image Affiliation ---*/}
                        {!["ANIM_2", "WEB_ANIM_2", "BANNER_2", "WEB_BANNER_2", "BG_BANNER_2", "WEB_BG_BANNER_2", "Challan_Banner_2", "WEB_Challan_Banner_2", "ICON_CONTENT_2", "WEB_ICON_CONTENT_2", "WEB_OFFER_BUTTON_2", "Notification_2", "SQUARE_POST_2", "OFFER_BUTTON_2", "ICON_2", "RC_CONTENT_2", "DIALOG_BANNER_2", "WEB_ICON"].includes(
                              adType.find((el) => el.value == languageImage?.adtype_id)?.label
                        )
                              && <div className="web_icon_content_2">
                                    <Row className="g-3">
                                          {languageImage?.data?.length > 0 && languageImage?.data?.map((item, index) => {
                                                const handleImageClick = () => {
                                                      const radio = document.getElementsByName("selectedImage")[index];
                                                      if (radio) {
                                                            radio.checked = !radio.checked;
                                                      }
                                                      if (selectedModalImage.id === item.id) {
                                                            setSelectedModalImage({ id: "", url: "" });
                                                      } else {
                                                            setSelectedModalImage({ id: item?.id, url: item.en });
                                                      }
                                                };
                                                return (<Col md="auto">
                                                      <div onClick={handleImageClick} className="aff_img_card">
                                                            <div className={`affimgbox ${selectedModalImage.id === item.id ? 'active' : ''}`}></div>
                                                            <img src={item?.[selectedLanguage]} alt="" className="img-fluid" />
                                                      </div>
                                                </Col>)
                                          })}
                                    </Row>
                              </div>}
                  </Modal.Body>
                  <Modal.Footer>
                        <Button variant="secondary me-2" onClick={() =>
                              ImagehandleClose()
                        }>Cancel</Button>
                        <Button variant="primary"
                              onClick={handleSelectImage}>Save</Button>
                  </Modal.Footer>
            </Modal>
      )
}

export default AffiliationImageModal