import React, { useContext, useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectPicker from 'rsuite/SelectPicker';
import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import Fancybox from "../../Component/FancyBox";
import Layout from "../../layout/Layout";
import { AddAffilationData, getAffilationData, getAffilationDropdown, getDynamicDropdown, getLanguageImage, getMakeModalDropDown } from "../../service/apis";
import { AuthContext } from "../../AppContext";
import { toast } from "react-toastify";
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import AddAffilation from "../../Component/AddAffilation";
import SubplaceAdd from "../../Component/AddPlace";
import AffiliationImageModal from "../../Component/AffiliationImageModal";

let serviceCategoryData = []
const AffiliationAdd = () => {
    const { userPermission } = useContext(AuthContext)
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [selectedImage, setSelectedImage] = useState({
        affiliationImage: {},
        defaultAffiliationImage: {}
    })
    const loction = useLocation()
    const navigate = useNavigate()
    const [allAffilationData, setAllAffilationData] = useState([]);
    const [defaultValue, setDefauleValue] = useState({});
    const [payload, setPayLoad] = useState({});
    const [allDropdown, setAllDropdown] = useState();
    const [allFieldDropdown, setAllFieldDropdown] = useState(null);
    const [affiliatiPlace, setAffiliatiPlace] = useState((loction?.state && loction?.state?.placed) || "");
    const [serviceCategory, setServiceCategory] = useState((loction?.state && loction?.state?.category) || "");
    const [groupId, setGroupId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState({ status: 1 });
    const [deletedIds, setDeletedIds] = useState([])
    const [validated, setValidated] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [imageshow, setImageShow] = useState(false);
    const [languageImage, setLanguageImage] = useState({ data: [], index: null, i: null, image: "", groupId: "", key: "", adtype_id: "" });
    const [placeObject, setPlaceObject] = useState()
    const [selectedModalImage, setSelectedModalImage] = useState({ id: "", url: "" })
    const [selectedLanguage, setSelectedLanguage] = useState("en")
    const [isSubmited, setIsSubmited] = useState(false);
    const [modalDropDown, setmodalDropDown] = useState([]);
    const [makerDropDown, setmakerDropDown] = useState([]);
    const [selectedPlaceArray, setSelectedPlaceArray] = useState('')

    const ImagehandleClose = () => setImageShow(false);
    // const ImagehandleShow = () => setImageShow(true);

    const [affshow, setAffShow] = useState(false);

    const handleAffClose = () => setAffShow(false);
    const handleAShow = () => setAffShow(true);


    const isNeedToShow = nextChecked => {
        setChecked({ ...defaultValue, status: nextChecked.target.checked == true ? 1 : 0 });
    };

    const uuid = () => {
        const uuid = Math.random().toString(36).slice(-6);
        return uuid
    }

    useEffect(() => {
        fetchDynamicDropdown()
        fetchAffilationDropdown()
    }, [])

    const getMakemodalDropDownBikeList = async (vehicleCategoryId) => {
        try {
            const response = await getMakeModalDropDown(vehicleCategoryId);
            const modal = response?.data?.data?.models?.map((item) => {
                return { value: item?.name, label: item?.name };
            });
            const makers = response?.data?.data?.makers?.map((item) => {
                return { value: item?.name, label: item?.name };
            });

            setmodalDropDown(modal)
            setmakerDropDown(makers)
        } catch (err) {
            console.log(err, "error in dropdown list fetch");
        }
    }


    useEffect(() => {
        allDropdown?.affiliation_place.length > 0 && setPlaceObject(allDropdown?.affiliation_place?.find((item) => item.id == (loction?.state && loction?.state?.placed)))
    }, [allDropdown?.affiliation_place])
    useEffect(() => {
        setAllAffilationData([]);
        if (allDropdown) {
            const place = allDropdown?.affiliation_place?.find((item) =>
                item.id === affiliatiPlace
            );
            if (affiliatiPlace && placeObject) {
                if (placeObject?.is_selected?.split(",").includes("4") && placeObject?.is_category == 1) {
                    serviceCategory && fetchAffilationData({ service_category: serviceCategory, place_id: affiliatiPlace });
                } else if (placeObject?.is_selected?.split(",").includes("2") && placeObject?.is_selected?.split(",").includes("4")) {
                    fetchAffilationData({ is_home_slider: true, place_id: affiliatiPlace });
                    setServiceCategory("");
                } else if (placeObject?.is_selected?.split(",").includes("4")) {
                    fetchAffilationData({ place_id: affiliatiPlace, is_group: true });
                    setServiceCategory("");
                }
                else {
                    fetchAffilationData({ place_id: affiliatiPlace });
                    setServiceCategory("");
                }
            }
        }
    }, [affiliatiPlace, serviceCategory, allDropdown, placeObject]);

    useEffect(() => {
        if (placeObject?.is_selected?.split(",")?.includes("4")) {
            const updatedAffilationData = { ...allAffilationData };
            if (updatedAffilationData?.affilation_data && updatedAffilationData?.affilation_data.length) {
                const addAffilation = [...updatedAffilationData.affilation_data];
                const nestedArray = addAffilation?.map((item) => item.affilation_data_list)
                const nestedDefault = addAffilation?.map((item) => item.default_affilation)
                const flattenedArray = [].concat(...nestedArray)
                setPayLoad({ ...payload, affilation_data: flattenedArray, default_value: nestedDefault.length > 0 ? nestedDefault.filter((s) => s) : [] })
            }

        }
        else {

            setPayLoad({ ...payload, affilation_data: allAffilationData })
        }
    }, [allAffilationData])

    // Filter Dropdown

    const affiliationPlaces = [];
    allDropdown?.affiliation_place?.map((item) =>
        affiliationPlaces.push({ label: item.name, value: item.id })
    );

    const serviceCategorys = [];
    allDropdown?.service_category?.map((item) =>
        serviceCategorys.push({ label: item.name, value: item.id })
    );
    const languages = ["en", "hi", "mr", "gu", "kn", "ta", "te", "bn", "ml", "or", "pa", "rj"];

    const language = []
    languages?.map((item) =>
        language.push({ label: item, value: item })
    );
    // Field Dropdown

    const lable = [];
    allFieldDropdown && allFieldDropdown?.lable?.map((item) =>
        lable.push({ label: item.lable, value: item.lable })
    );
    const serviceProvider = [];
    allFieldDropdown && allFieldDropdown?.service_provider?.map((item) =>
        serviceProvider.push({ label: item.provider, value: item.id })
    );
    const affilationservice = [];
    allFieldDropdown && allFieldDropdown?.affilation_service?.map((item) =>
        affilationservice.push({ label: item.services, value: item.id })
    );
    const adType = [];
    allFieldDropdown && allFieldDropdown?.ad_type?.map((item) =>
        adType.push({ label: item.type, value: item.id })
    );
    const utmTerm = [];
    allFieldDropdown && allFieldDropdown?.utm_term?.map((item) =>
        utmTerm.push({ label: item, value: item })
    );

    // change values......
    const handleTestOnChange = (value, index, field, nestedField = null, rangeNumber = null) => {
        setAllAffilationData((prevData) => {
            return prevData.map((item, i) => {
                if (i === index) {
                    if (nestedField) {
                        if (/^\d*$/.test(value)) {
                            if (rangeNumber) {
                                const currentRange = item[field]?.[nestedField]?.split('-') || ['', ''];
                                currentRange[rangeNumber - 1] = value;
                                return {
                                    ...item,
                                    [field]: {
                                        ...item[field],
                                        [nestedField]: currentRange.join('-'),
                                    },
                                };
                            }
                            else {
                                return {
                                    ...item,
                                    [field]: {
                                        ...item[field],
                                        [nestedField]: value,
                                    },
                                };
                            }
                        }
                        return item;
                    }

                    else if (field == 'maker' || field == 'model' || field == 'city_id_list' || field == 'vehicle_type' || field == 'manufuring_year' || field == 'city_id_list') {
                        const array_value = value?.map(item => item.value)?.join(',') || ''
                        return {
                            ...item,
                            [field]: array_value
                        }
                    }
                    else if (field == 'status') {
                        return {
                            ...item,
                            [field]: value ? 1 : 0
                        }
                    }
                    else if (field == 'is_specify_city' || field == 'is_allCity') {
                        if (field == 'is_specify_city') {
                            return {
                                ...item,
                                city_id_list: value ? "" : "All",
                                blocked_pincodevalue: value ? item?.blocked_pincodevalue : ''
                            }
                        }
                        else {
                            return {
                                ...item,
                                city_id_list: value ? "All" : '',
                                blocked_pincode: value ? '' : item?.blocked_pincode
                            }
                        }
                    }

                    else if (field == "blocked_pincode") {
                        const input = value;
                        const regex = /^[0-9,]+$/;
                        if (regex.test(input) || input === "") {
                            const pin = input?.replaceAll(",,", ",")?.replaceAll(" ", "");
                            return {
                                ...item,
                                [field]: pin,
                            };
                        } else {
                            console.error("Invalid input: Only numbers and commas are allowed.");
                            return item;
                        }
                    }
                    return {
                        ...item,
                        [field]: value,
                    };
                }
                return item;
            });
        });

    };

    const handleField = (e, index, field) => {
        setValidated(false)

        let updatedData = [...allAffilationData];
        if (field == "is_priority") {
            updatedData = updatedData.map((item, i) => i !== index ? { ...item, is_priority: 0 } : item);
        }
        updatedData[index][field] = e === true ? 1 : e === false ? 0 : e
        setAllAffilationData(updatedData);
    };

    const handleNestedArrayUpdate = (e, index, i, property) => {
        setValidated(false)

        setAllAffilationData((prevData) => {
            let updatedData = { ...prevData };
            const updatedAffilationData = [...updatedData.affilation_data];
            const updatedItem = { ...updatedAffilationData[index] };

            if (property === "is_priority") {
                updatedItem.affilation_data_list = updatedItem.affilation_data_list.map((item, innerIndex) => innerIndex !== i ? { ...item, is_priority: 0 } : item);
            }

            updatedItem.affilation_data_list[i][property] = e === true ? 1 : e === false ? 0 : e ?? null;
            updatedAffilationData[index] = updatedItem;
            updatedData.affilation_data = updatedAffilationData;

            return updatedData;
        });
    };

    const defaultChange = (e, index, property, groupId) => {
        setAllAffilationData((prevData) => {
            const updatedData = { ...prevData };
            const updatedAffilationData = [...updatedData.affilation_data];
            const updatedItem = { ...updatedAffilationData[index] };
            if (!updatedItem.default_affilation) {
                updatedItem.default_affilation = {};
            }

            // Update the property of the item
            updatedItem.default_affilation[property] = e === true ? 1 : e === false ? 0 : e;
            updatedItem.default_affilation.group_id = groupId;
            if (!updatedItem.default_affilation.new_id) {
                updatedItem.default_affilation.new_id = uuid();
            }
            updatedItem.default_affilation.is_default = 1;

            // Update the item in the copied array
            updatedAffilationData[index] = updatedItem;

            // Update the affilation_data array in the copied data
            updatedData.affilation_data = updatedAffilationData;
            return updatedData;
        });
    }
    const handleDefaultValue = (value, field, nestedField = null, rangeNumber = null) => {
        setDefauleValue((prev) => {
            if (nestedField) {
                if (rangeNumber) {
                    const currentRange = prev[field]?.[nestedField]?.split('-') || ['', ''];
                    currentRange[rangeNumber - 1] = value;
                    return {
                        ...prev,
                        [field]: {
                            ...prev[field],
                            [nestedField]: currentRange.join('-'),
                        },
                    };
                }
                return {
                    ...prev,
                    [field]: {
                        ...prev[field],
                        [nestedField]: value,
                    },
                };
            }
            else if (field == 'maker' || field == 'model' || field == 'city_id_list' || field == 'vehicle_type' || field == 'manufuring_year' || field == 'city_id_list') {

                const array_value = value?.map(item => item.value)?.join(',')
                return {
                    ...prev,
                    [field]: array_value
                }
            }
            else if (field == 'is_specify_city' || field == 'is_allCity') {
                if (field == 'is_specify_city') {
                    return {
                        ...prev,
                        city_id_list: value ? "" : "All"
                    }
                }
                else {
                    return {
                        ...prev, city_id_list: value ? "All" : ""
                    }
                }
            }

            else if (field == "blocked_pincode") {
                const input = value;
                const regex = /^[0-9,]+$/;
                if (regex.test(input) || input == "") {
                    const pin = input?.replaceAll(",,", ",")?.replaceAll(" ", "");
                    return {
                        ...prev,
                        [field]: pin,
                    };
                } else {
                    console.error("Invalid input: Only numbers and commas are allowed.");
                    return prev;
                }


            }

            return {
                ...prev,
                [field]: value,
            };
        })
    }

    const handleDefaultField = (e, property) => {
        setDefauleValue((prevData) => {
            const updatedData = { ...prevData };
            if (!updatedData) {
                updatedData = {};
            }
            updatedData[property] = e === true ? 1 : e === false ? 0 : e
            updatedData.is_default = 1
            if (!updatedData.new_id) {
                updatedData.new_id = uuid()
            }
            return updatedData;

        })
    }
    const handleDefaultImage = (e, newId, id, group_id) => {
        if (newId) {
            setPayLoad({ ...payload, [`new_banner_image_${newId}`]: e.target.files[0] })
            setSelectedImage({
                ...selectedImage,
                defaultAffiliationImage: {
                    ...selectedImage.defaultAffiliationImage,
                    [group_id]: e.target.files[0].name,
                },
            });
        } else {
            setPayLoad({ ...payload, [`banner_image_${id}`]: e.target.files[0] })
            setSelectedImage({
                ...selectedImage,
                defaultAffiliationImage: {
                    ...selectedImage.defaultAffiliationImage,
                    [group_id]: e.target.files[0].name,
                },
            });
        }
    };
    const handleImage = (e, index, newId, id) => {
        setIsImage(true);
        if (newId) {
            setPayLoad({ ...payload, [`new_banner_image_${newId}`]: e.target.files[0] })
            setSelectedImage({
                ...selectedImage,
                affiliationImage: {
                    ...selectedImage.affiliationImage,
                    [newId]: e.target.files[0].name,
                },
            });
        } else {
            setPayLoad({ ...payload, [`banner_image_${id}`]: e.target.files[0] })
            setSelectedImage({
                ...selectedImage,
                affiliationImage: {
                    ...selectedImage.affiliationImage,
                    [id]: e.target.files[0].name,
                },
            });
        }



    };
    const handleImageChange = (e, index, newId, id) => {

        setIsImage(true)
        if (newId) {
            setPayLoad({ ...payload, [`new_banner_image_${newId}`]: e.target.files[0] })
            setSelectedImage({
                ...selectedImage,
                affiliationImage: {
                    ...selectedImage.affiliationImage,
                    [newId]: e.target.files[0].name,
                },
            });
        } else {
            setPayLoad({ ...payload, [`banner_image_${id}`]: e.target.files[0] })
            setSelectedImage({
                ...selectedImage,
                affiliationImage: {
                    ...selectedImage.affiliationImage,
                    [id]: e.target.files[0].name,
                },
            });
        }


    };

    //Fetch Api
    const fetchDynamicDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getDynamicDropdown()
            setAllDropdown(response?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchAffilationDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getAffilationDropdown()
            setAllFieldDropdown(response?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchAffilationData = async (payload) => {
        setIsLoading(true);
        try {
            const response = await getAffilationData(payload)

            if (placeObject?.is_selected?.split(",").includes("4")) {
                setAllAffilationData(response?.data?.data)
                setGroupId(response?.data?.data?.last_group_id || 0)
            }
            else {
                setAllAffilationData(response?.data?.data?.affilation_data);
                setDefauleValue(response?.data?.data?.default_affilation);
                // response?.data?.data?.affilation_data?.length <= 0 && addNewAffilation()
            }

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const AddNewAffilationData = async (payload) => {
        setIsLoading(true);
        try {
            const response = await AddAffilationData(payload)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    //Add new Affilation
    const addNewAffilation = () => {
        if (allAffilationData?.affilation_data?.length || allAffilationData?.length) {
            document.getElementById("affiliation").reset()
            document.getElementById("default") && document.getElementById("default").reset()
        }
        if (((allAffilationData?.affilation_data?.length || allAffilationData?.length) >= placeObject?.place_limit) && placeObject?.is_selected?.split(",")?.includes("4")) {
            toast.error("Please Increase Your Limit", { autoClose: 2000 })
        } else {
            setValidated(false)
            if (placeObject?.is_selected?.split(",")?.includes("4")) {
                const updatedAffilationData = { ...allAffilationData };
                const addAffilation = [...updatedAffilationData?.affilation_data];
                addAffilation?.unshift({
                    group_id: groupId + 1 || addAffilation.length + 1,
                    affilation_data_list: [
                        {
                            group_id: groupId + 1 || addAffilation?.length + 1,
                            new_id: uuid(),
                            affiliate_id: "",
                            is_default: 0,
                            service_provider_id: "",
                            affiliation_services_id: "",
                            ad_type_id: "",
                            utm_term: "",
                            url: "",
                            status: 0,
                            is_deep_integration: 0,
                            activity: "",
                            lable: "",
                            banner: null,
                            is_priority: 0,
                        },
                    ],
                });
                updatedAffilationData.affilation_data = addAffilation;
                setAllAffilationData(updatedAffilationData);
                setGroupId(groupId + 1 || addAffilation?.length + 1)
            } else {
                const addAffilation = [...allAffilationData];
                addAffilation?.unshift({
                    new_id: uuid(),
                    lable: "",
                    service_provider_id: "",
                    affiliation_services_id: "",
                    ad_type_id: "",
                    is_priority: 0,
                    url: "",
                    utm_term: "",
                    image: "",
                    status: 0,
                    is_deep_integration: 0,
                    activity: "",
                });
                setAllAffilationData(addAffilation);
            }// Insert newObj at the beginning
        }
    }
    // useEffect(() => {
    //     ((Object.keys(allAffilationData).length !== 0) &&
    //         allAffilationData?.affilation_data?.length == 0 &&
    //         placeObject?.is_selected?.split(",").includes("4"))
    //         ?
    //         addNewAffilation()
    //         : ""

    // }, [allAffilationData])
    const handleAdd = (index, groupId) => {
        // Create a new copy of the group state
        setValidated(false)
        const updatedAffilationData = { ...allAffilationData };
        const newItem = {
            new_id: uuid(),
            lable: "",
            group_id: groupId,
            service_provider_id: "",
            affiliation_services_id: "",
            ad_type_id: "",
            is_priority: 0,
            url: "",
            utm_term: "",
            image: "",
            status: 0,
            is_deep_integration: 0,
            activity: "",
        };
        const addAffilation = [...updatedAffilationData.affilation_data];
        addAffilation[index].affilation_data_list.push(newItem)
        updatedAffilationData.affilation_data = addAffilation
        setAllAffilationData(updatedAffilationData);
    }

    //Delete affilation
    const handleDelete = (index, id, newId) => {
        if (newId) {
            if (payload[`new_banner_image_${newId}`]) {
                delete payload[`new_banner_image_${newId}`]
                setPayLoad(payload)

            }
        } else {
            // banner_image_
            if (payload[`banner_image_${id}`]) {
                delete payload[`banner_image_${id}`]
                setPayLoad(payload)

            }
        }
        if (id) {
            setDeletedIds([...deletedIds, id])
        }
        setAllAffilationData(allAffilationData?.length > 0 && allAffilationData?.filter((item, i) => i !== index))
    }
    const handleDeleteGroupElement = (index, i, item) => {
        const updatedAffilationData = { ...allAffilationData };
        const addAffilation = [...updatedAffilationData.affilation_data];

        if (item.id) {
            setDeletedIds([...deletedIds, item.id])
        }
        // Use filter to remove the element at the specified inner index
        addAffilation[index].affilation_data_list = addAffilation[index].affilation_data_list.filter((el, ind) => ind !== i);
        setAllAffilationData(updatedAffilationData)


    }
    const handleDeleteGroup = (index, item) => {
        const updatedAffilationData = { ...allAffilationData };
        const addAffilation = [...updatedAffilationData.affilation_data];
        const updatedAddAffilation = addAffilation.filter((el, i) => i !== index);
        updatedAffilationData.affilation_data = updatedAddAffilation;
        setAllAffilationData(updatedAffilationData)
        let ids = []
        if (item.affilation_data_list && item.affilation_data_list.length) {
            for (let i = 0; i < item.affilation_data_list.length; i++) {
                const element = item.affilation_data_list[i];
                if (element.id) {
                    ids.push(element.id)
                }
            }
        }
        if (item.default_affilation) {
            if (item.default_affilation.id) {
                ids.push(item.default_affilation.id)
            }
        }
        setDeletedIds([...deletedIds, ...ids])

    }

    // const vadidate = async () => {
    // }

    const validate = () => {
        const checkVehicleRange = (range) => {
            if (!range || range == '-') return false;
            const [min, max] = range.split("-");
            if ((!min && max) || (min && !max)) {
                return true
            }
            else if (+min >= Number(max)) {
                return true
            }
            return false
        };

        if (placeObject?.is_selected?.split(",").includes("4")) {
            return (
                allAffilationData?.affilation_data?.length > 0 &&
                allAffilationData?.affilation_data?.some((item, index) =>
                    item?.affilation_data_list?.some(el => {
                        return !el?.affiliation_services_id || !el.service_provider_id || !el.ad_type_id || !el.utm_term || !el.lable || !el.url || ((!el.banner && !selectedImage.affiliationImage[el.new_id || el.id]) && !el.affiliation_banner_image);
                    })
                )
            );
        }
        else {
            return allAffilationData?.some(el => !el?.affiliation_services_id || !el.service_provider_id || !el.ad_type_id || !el.utm_term || !el.lable || !el.url || ((!el.banner && !selectedImage.affiliationImage[el.new_id || el.id]) && !el.affiliation_banner_image));
        }
    };

    const handleSave = async () => {
        // setIsLoading(true)
        setValidated(true)
        if (!validate() && affiliatiPlace) {
            let result = []
            if (placeObject?.is_selected?.split(",").includes("4")) {
                if (payload && payload.default_value && payload.default_value.length) {
                    for (let i = 0; i < payload.affilation_data.length; i++) {
                        const element = payload.affilation_data[i];
                        const default_value = payload.default_value.find(x => x.group_id === element.group_id)
                        const default_value_index = payload.default_value.findIndex(x => x.group_id === element.group_id)
                        if (default_value && (payload.affilation_data[i + 1] && payload.affilation_data[i + 1].group_id != default_value.group_id)) {
                            await result.push(element)
                            if ((default_value.ad_type_id && default_value.ad_type_id != "") &&
                                (default_value.affiliation_services_id && default_value.affiliation_services_id != "") &&
                                (default_value.service_provider_id && default_value.service_provider_id != "") &&
                                (default_value.group_id && default_value.group_id != "") &&
                                (default_value.utm_term && default_value.utm_term != "") &&
                                (default_value.url && default_value.url != "") &&
                                (default_value.lable && default_value.lable != "")) {
                                result.push(default_value)

                            }
                            if (default_value_index !== -1) {
                                payload.default_value.splice(default_value_index, 1);
                            }
                        } else {
                            result.push(element)
                        }

                    }
                    if (payload.default_value.length) {
                        result.push(...payload.default_value)
                    }
                } else {
                    result = payload.affilation_data
                }
            }
            else {
                result = payload.affilation_data
                if (defaultValue && (defaultValue.ad_type_id && defaultValue.ad_type_id != "") &&
                    (defaultValue.affiliation_services_id && defaultValue.affiliation_services_id != "") &&
                    (defaultValue.service_provider_id && defaultValue.service_provider_id != "") &&
                    (defaultValue.utm_term && defaultValue.utm_term != "") &&
                    (defaultValue.url && defaultValue.url != "") &&
                    (defaultValue.lable && defaultValue.lable != "")) {
                    result.push(defaultValue)
                }

            }
            delete payload.affilation_data
            let _response = {
                ...payload,
                affilation_data: JSON.stringify(result),
                is_need_to_show: checked.status,
                affiliation_place_id: affiliatiPlace,
            }
            if (serviceCategory) {
                _response.service_category = serviceCategory
                _response.type = allAffilationData?.place_name
            }
            const formData = new FormData();
            if (Object.keys(_response).length) {
                Object.keys(_response).map((key) => {
                    formData.append(key, _response[key])
                })
            }
            formData.append('deletedIds', JSON.stringify(deletedIds))
            const place = allDropdown?.affiliation_place?.find((item) =>
                item.id === affiliatiPlace
            );
            AddNewAffilationData(formData).then(async (res) => {
                if (placeObject?.is_selected?.split(",").includes("4") && placeObject?.is_category == 1) {
                    serviceCategory && fetchAffilationData({ service_category: serviceCategory, place_id: affiliatiPlace });
                } else if (placeObject?.is_selected?.split(",").includes("2") && placeObject?.is_selected?.split(",").includes("4")) {
                    fetchAffilationData({ is_home_slider: true, place_id: affiliatiPlace });
                    setServiceCategory("");
                } else if (placeObject?.is_selected?.split(",").includes("4")) {
                    fetchAffilationData({ place_id: affiliatiPlace, is_group: true });
                    setServiceCategory("");
                }
                else {
                    fetchAffilationData({ place_id: affiliatiPlace });
                    setServiceCategory("");
                }
            })
            // setIsLoading(false)
            toast.success("Affiliation update successfully!!")
            // AddNewAffilationData(formData)
        } else { setValidated(true) }
    }

    //Modal Images
    const getImages = async (index, i, e, imageId, key) => {
        const res = await getLanguageImage({ adtype_id: e });
        setLanguageImage({ data: res?.data?.data, index, i, image: imageId, key, adtype_id: e });
        setSelectedModalImage({ id: imageId, url: "" });
        setImageShow(true)
    }
    const getDefaultImages = async (e, index, groupId, imageId, key) => {
        const res = await getLanguageImage({ adtype_id: e });
        setLanguageImage({ data: res?.data?.data, index, image: imageId, groupId, key, adtype_id: e });
        setSelectedModalImage({ id: imageId, url: "" });
        setImageShow(true)
    }

    //handle select Image
    const handleSelectImage = () => {
        if (languageImage.key == "is_group") {
            handleNestedArrayUpdate(selectedModalImage.id, languageImage.index, languageImage.i, 'affiliation_banner_image')
            handleNestedArrayUpdate(selectedModalImage.url, languageImage.index, languageImage.i, 'get_affilation_banner_image')
        }
        if (languageImage.key == "is_other") {
            handleField(selectedModalImage.id, languageImage.i, 'affiliation_banner_image')
            handleField(selectedModalImage.url, languageImage.i, 'get_affilation_banner_image')
        }
        if (languageImage.key == "is_group_default") {
            defaultChange(selectedModalImage.id, languageImage.index, 'affiliation_banner_image', languageImage.groupId)
            defaultChange(selectedModalImage.url, languageImage.index, 'get_affilation_banner_image', languageImage.groupId)
        }
        if (languageImage.key == "is_other_default") {
            handleDefaultField(selectedModalImage.id, 'affiliation_banner_image')
            handleDefaultField(selectedModalImage.url, 'get_affilation_banner_image')
        }
        setImageShow(false)
    }

    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading backbtn">
                    <h3><Link to="/affiliation" className='btn btn-transparent btn-icon me-2'><i className='bx bx-chevron-left'></i></Link>{loction?.state && loction?.state?.placed ? "Affiliation Program Edit" : "Affiliation Program Add"}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/affiliation">Affiliation Program</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{loction?.state && loction?.state?.placed ? "Affiliation Program Edit" : "Affiliation Program Add"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Row className="g-4">
                        <Col sm={12}>
                            <Card className="radiustop-0">
                                <Card.Body>
                                    <Row>
                                        <Col lg={3} md={4}>
                                            <Form.Label>Place</Form.Label>
                                            <SelectPicker
                                                cleanable={false}
                                                data={affiliationPlaces}
                                                onChange={(e) => {
                                                    setValidated(false)
                                                    setDeletedIds([])
                                                    setAffiliatiPlace(e)
                                                    setPlaceObject(allDropdown?.affiliation_place?.find((item) => item.id == e))
                                                    setAllAffilationData([])
                                                }}
                                                value={affiliatiPlace}
                                                block
                                                className={`my-2 ${(!affiliatiPlace && validated) && "rs-novalidated"}`}
                                                placeholder="Select Place"
                                            />
                                            {(!affiliatiPlace && validated) && <div className="rs-invalid-feedback">Select Place</div>}
                                        </Col>
                                        {(placeObject?.is_category == 1) && <Col lg={3} md={4}>
                                            <Form.Label>{"Service Category"}</Form.Label>
                                            <SelectPicker
                                                cleanable={false}
                                                data={serviceCategorys}
                                                value={serviceCategory}
                                                onChange={(e) => {
                                                    setDeletedIds([])
                                                    setServiceCategory(e)
                                                }}
                                                block
                                                className="my-2"
                                                placeholder={`Select Service Category`}
                                            />
                                        </Col>}
                                        <Col lg={3} md={4}>
                                            <Form.Label>Is Need to Show</Form.Label>
                                            <Form.Check type="switch" className="my-2" onChange={isNeedToShow} checked={checked?.status} />
                                            {/* <Form.Check type="switch" className="my-2" onChange={isNeedToShow} checked={checked} /> */}
                                        </Col>
                                        {
                                            userPermission["Affilation-Program"]?.add == 1 && (!(affiliatiPlace == 19 && serviceCategory == "") &&
                                                <Col lg={3} md={4} className="align-self-end my-2 text-end">
                                                    <Button onClick={addNewAffilation} variant="primary">Add New</Button>
                                                </Col>)
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        {placeObject?.is_selected?.split(",").includes("4") ? allAffilationData?.affilation_data?.length > 0 && allAffilationData?.affilation_data?.map((item, index) => {
                            return (<Col key={index} sm={12}>
                                <Card>
                                    <Card.Header className="border-bottom">
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <h4 className="m-0 fw-bold">Group {item?.group_id}</h4>
                                            <div>
                                                {userPermission["Affilation-Program"]?.add == 1 && <Button variant="primary" size="sm" onClick={() => handleAdd(index, item?.group_id)} className="btn-icon-lg"><i className='bx bx-plus'></i></Button>}
                                                {userPermission["Affilation-Program"]?.delete == 1 && <Button variant="danger" size="sm" onClick={() => handleDeleteGroup(index, item)} className="btn-icon-lg ms-3"><i className='bx bx-x'></i></Button>}
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="g-4">
                                            {item?.affilation_data_list?.length > 0 && item?.affilation_data_list?.map((el, i) => {
                                                return (
                                                    <Form id="affiliation" noValidate validated={validated}>
                                                        <Col key={el.id} sm={12}>
                                                            <Card className="border">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Services Provider</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={serviceProvider}
                                                                                block
                                                                                onChange={(e) => handleNestedArrayUpdate(e, index, i, 'service_provider_id')}
                                                                                value={el?.service_provider_id}
                                                                                className={`my-2 ${(!el?.service_provider_id && validated) && "rs-novalidated"}`}
                                                                                placeholder="Select Services Provider"
                                                                            />
                                                                            {(!el?.service_provider_id && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Service Provider
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Services</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={affilationservice}
                                                                                block
                                                                                onChange={(e) => handleNestedArrayUpdate(e, index, i, 'affiliation_services_id')}
                                                                                value={el?.affiliation_services_id}
                                                                                className={`my-2 ${(!el?.affiliation_services_id && validated) && "rs-novalidated"}`}
                                                                                placeholder="Select Services"
                                                                            />
                                                                            {(!el?.affiliation_services_id && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Affiliation Services
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Ad Type</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={adType}
                                                                                block
                                                                                value={el?.ad_type_id}
                                                                                onChange={(e) => handleNestedArrayUpdate(e, index, i, 'ad_type_id')}
                                                                                className={`my-2 ${(!el?.ad_type_id && validated) && "rs-novalidated"}`}
                                                                                placeholder="Select Ad Type"
                                                                            />
                                                                            {(!el?.ad_type_id && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Ad Type
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>UTM Term</Form.Label>
                                                                            <CreatableSelect isClearable options={utmTerm}
                                                                                onChange={(e) => handleNestedArrayUpdate(e.value, index, i, 'utm_term')}
                                                                                defaultValue={{ label: el.utm_term, value: el.utm_term }}
                                                                                placeholder="Select UTM Term"
                                                                                className={`customMulSelect my-2 ${(!el?.utm_term && validated) && "rs-novalidated"}`}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {(!el?.utm_term && validated) && <div className='rs-invalid-feedback'>
                                                                                Select UTM Term
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Lable</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={lable}
                                                                                block
                                                                                value={el?.lable}
                                                                                onChange={(e) => handleNestedArrayUpdate(e, index, i, 'lable')}
                                                                                className={`my-2 ${(!el?.lable && validated) && "rs-novalidated"}`}
                                                                                // menuClassName="menu-custom wv-200"
                                                                                placeholder="Select Lable"
                                                                            />
                                                                            {(!el?.lable && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Lable
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>URL</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="title"
                                                                                value={el?.url}
                                                                                onChange={(e) => handleNestedArrayUpdate(e.target.value?.trimStart(), index, i, 'url')}
                                                                                className="my-2"
                                                                                required
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Enter URL
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Banner</Form.Label>
                                                                            <InputGroup className="my-2 input-file-choose-custome">
                                                                                <Form.Control type="file" onChange={(e) => {
                                                                                    handleImageChange(e, i, el?.new_id && el?.new_id, el?.id && el?.id);
                                                                                    //  handleNestedArrayUpdate(e.target.files[0], index, i, 'banner')
                                                                                }} name="title"
                                                                                    className={` ${((!el.banner && !selectedImage.affiliationImage[el.new_id || el.id]) && !el.affiliation_banner_image && validated) ? "invalid-custom" : ""}`}

                                                                                // {...(((!el?.banner&&!selectedImage.affiliationImage[el.new_id||el.id]) && !el.get_affilation_banner_image) && { required: true })} />
                                                                                />
                                                                                <p>{selectedImage.affiliationImage[el.new_id || el.id] ? selectedImage.affiliationImage[el.new_id || el.id] : 'No file chosen'}</p>
                                                                                {((!el.banner && !selectedImage.affiliationImage[el.new_id || el.id]) && !el.affiliation_banner_image && validated) && <div className='rs-invalid-feedback'>
                                                                                    Select Banner
                                                                                </div>}
                                                                                {/* <Form.Control.Feedback type="invalid">
                                                                                    Select Banner
                                                                                </Form.Control.Feedback> */}
                                                                                <Fancybox>
                                                                                    <a href={el?.banner} data-fancybox="gallery">
                                                                                        <img src={el?.banner} className="hv-40 rounded-3" alt="" />
                                                                                    </a>
                                                                                </Fancybox>
                                                                            </InputGroup>
                                                                        </Col>
                                                                        {el?.ad_type_id && <Col lg={3} md={4} className="align-self-end">
                                                                            <div className="d-flex align-items-center mb-2">
                                                                                <Button variant="primary" onClick={() => getImages(index, i, el?.ad_type_id, el.affiliation_banner_image, "is_group")}>Select Image</Button>
                                                                                <Fancybox>
                                                                                    <a href={el?.get_affilation_banner_image} data-fancybox="gallery">
                                                                                        <img src={el?.get_affilation_banner_image} className="hv-40 rounded-3 ms-3" alt="" />
                                                                                    </a>
                                                                                </Fancybox>
                                                                            </div>
                                                                        </Col>}
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Activity</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="title"
                                                                                value={el?.activity}
                                                                                onChange={(e) => handleNestedArrayUpdate(e.target.value?.trimStart(), index, i, 'activity')}
                                                                                className="my-2"
                                                                                placeholder="Enter Activity"
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Enter Activity
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                        <Col lg={1} md={4}>
                                                                            <Form.Label>Status</Form.Label>
                                                                            <Form.Check type="switch" className="my-2" onChange={(e) => handleNestedArrayUpdate(e.target.checked, index, i, 'status')} checked={el?.status == 1 ? true : false} />
                                                                        </Col>
                                                                        <Col lg={1} md={4}>
                                                                            <Form.Label>Priority</Form.Label>
                                                                            <Form.Check type="switch" className="my-2" onChange={(e) => handleNestedArrayUpdate(e.target.checked, index, i, 'is_priority')} checked={el?.is_priority == 1 ? true : false} />
                                                                        </Col>
                                                                        <Col lg={2} md={4}>
                                                                            <Form.Label>Is Deep Integration</Form.Label>
                                                                            <Form.Check type="switch" className="my-2" onChange={(e) => handleNestedArrayUpdate(e.target.checked, index, i, 'is_deep_integration')} checked={el?.is_deep_integration == 1 ? true : false} />
                                                                        </Col>
                                                                        <Col lg={1} md={4} className="align-self-end my-2 text-end">
                                                                            <Button variant="danger" onClick={() => handleDeleteGroupElement(index, i, el)} size="sm" className="btn-icon-lg ms-3"><i className='bx bx-x'></i></Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col></Form>)
                                            })}

                                            <Col sm={12}>
                                                <Card className="border">
                                                    <Card.Header className="border-bottom">
                                                        <h5 className="m-0 fw-bold">Default Value</h5>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Form id="default">
                                                            <Row >
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>Services Provider</Form.Label>
                                                                    <SelectPicker
                                                                        cleanable={false}
                                                                        data={serviceProvider}
                                                                        onChange={(e) => defaultChange(e, index, "service_provider_id", item?.group_id)}
                                                                        value={item?.default_affilation?.service_provider_id}
                                                                        block
                                                                        className="my-2"
                                                                        placeholder="Select Services Provider"
                                                                    />
                                                                </Col>
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>Services</Form.Label>
                                                                    <SelectPicker
                                                                        cleanable={false}
                                                                        data={affilationservice}
                                                                        value={item?.default_affilation?.affiliation_services_id}
                                                                        onChange={(e) => defaultChange(e, index, "affiliation_services_id", item?.group_id)}
                                                                        block
                                                                        className="my-2"
                                                                        placeholder="Select Services"
                                                                    />
                                                                </Col>
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>Ad Type</Form.Label>
                                                                    <SelectPicker
                                                                        cleanable={false}
                                                                        data={adType}
                                                                        block
                                                                        value={item?.default_affilation?.ad_type_id}
                                                                        onChange={(e) => defaultChange(e, index, "ad_type_id", item?.group_id)}
                                                                        className="my-2"
                                                                        placeholder="Select Ad Type"
                                                                    />
                                                                </Col>
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>UTM Term</Form.Label>
                                                                    <CreatableSelect isClearable options={utmTerm}
                                                                        onChange={(e) => defaultChange(e.value, index, "utm_term", item?.group_id)}
                                                                        placeholder="Select UTM Term"
                                                                        className='customMulSelect my-2'
                                                                        classNamePrefix="react-select"
                                                                        defaultValue={{ label: item?.default_affilation?.utm_term, value: item?.default_affilation?.utm_term }}

                                                                    />
                                                                    {/* <SelectPicker
                                                                    cleanable={false}
                                                                    data={utmTerm}
                                                                    value={item?.default_affilation?.utm_term}
                                                                    onChange={(e) => defaultChange(e, index, "utm_term", item?.group_id)}
                                                                    block
                                                                    className="my-2"
                                                                    placement="bottomEnd"
                                                                    placeholder="Select UTM Term"
                                                                /> */}
                                                                </Col>
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>Lable</Form.Label>
                                                                    <SelectPicker
                                                                        cleanable={false}
                                                                        data={lable}
                                                                        value={item?.default_affilation?.lable}
                                                                        onChange={(e) => defaultChange(e, index, "lable", item?.group_id)}
                                                                        block
                                                                        className="my-2"
                                                                        placeholder="Select Lable"
                                                                    />
                                                                </Col>
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>URL</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={item?.default_affilation?.url ? item?.default_affilation?.url : ''}
                                                                        onChange={(e) => defaultChange(e.target.value?.trimStart(), index, "url", item?.group_id)}
                                                                        name="title"
                                                                        className="my-2"
                                                                    />
                                                                </Col>
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>Banner</Form.Label>
                                                                    <InputGroup className="my-2 input-file-choose-custome">
                                                                        <Form.Control type="file" onChange={(e) => handleDefaultImage(e, item?.default_affilation?.new_id && item?.default_affilation?.new_id, item?.default_affilation?.id && item?.default_affilation?.id, item?.group_id)} name="title" />
                                                                        <p>{selectedImage?.defaultAffiliationImage[item?.group_id] ? selectedImage?.defaultAffiliationImage[item?.group_id] : 'No file chosen'}</p>
                                                                        <Fancybox>
                                                                            <a href={item?.default_affilation?.banner} data-fancybox="gallery">
                                                                                <img src={item?.default_affilation?.banner} className="hv-40 rounded-3" alt="" />
                                                                            </a>
                                                                        </Fancybox>
                                                                    </InputGroup>
                                                                </Col>
                                                                {item?.default_affilation?.ad_type_id && <Col lg={3} md={4} className="align-self-end">
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <Button variant="primary"
                                                                            onClick={() => getDefaultImages(item?.default_affilation?.ad_type_id, index, item?.group_id, item?.default_affilation?.affiliation_banner_image, "is_group_default")}
                                                                        >Select Image</Button>
                                                                        <Fancybox>
                                                                            <a href={item?.default_affilation?.get_affilation_banner_image} data-fancybox="gallery">
                                                                                <img src={item?.default_affilation?.get_affilation_banner_image} className="hv-40 rounded-3 ms-3" alt="" />
                                                                            </a>
                                                                        </Fancybox>
                                                                    </div>
                                                                </Col>}
                                                                <Col lg={3} md={4}>
                                                                    <Form.Label>Activity</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={item?.default_affilation?.activity ? item?.default_affilation?.activity : ''}
                                                                        onChange={(e) => defaultChange(e.target.value?.trimStart(), index, "activity", item?.group_id)}
                                                                        name="title"
                                                                        className="my-2"
                                                                        placeholder="Enter Activity"
                                                                    />
                                                                </Col>
                                                                <Col lg={1} md={4}>
                                                                    <Form.Label>Status</Form.Label>
                                                                    <Form.Check type="switch" className="my-2"
                                                                        onChange={(e) => defaultChange(e.target.checked, index, "status", item?.group_id)}
                                                                        checked={item?.default_affilation?.status ? item?.default_affilation?.status : false} />
                                                                </Col>
                                                                <Col lg={2} md={4}>
                                                                    <Form.Label>Is Deep Integration</Form.Label>
                                                                    <Form.Check type="switch" className="my-2"
                                                                        onChange={(e) => defaultChange(e.target.checked, index, "is_deep_integration", item?.group_id)}
                                                                        checked={item?.default_affilation?.is_deep_integration ? item?.default_affilation?.is_deep_integration : false} />
                                                                </Col>
                                                            </Row></Form>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>)
                        }) :
                            // !(affiliatiPlace == 19 && serviceCategory == "") &&
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Row className="g-4">
                                            {/* Affilition New */}
                                            {allAffilationData?.length > 0 && allAffilationData?.map((el, i) => {
                                                return (
                                                    <Form id="affiliation" key={i} noValidate validated={validated}>
                                                        <Col sm={12}>
                                                            <Card className="border">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Services Provider</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={serviceProvider}
                                                                                block
                                                                                onChange={(e) => handleField(e, i, "service_provider_id")}
                                                                                value={el?.service_provider_id}
                                                                                className={`my-2 ${(!el?.service_provider_id && validated) && "rs-novalidated"}`}
                                                                                placeholder="Select Services Providers"
                                                                            />
                                                                            {(!el?.service_provider_id && validated) && (
                                                                                <div className='rs-invalid-feedback'>
                                                                                    Select Service Provider
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Services</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={affilationservice}
                                                                                block
                                                                                onChange={(e) => handleField(e, i, "affiliation_services_id")}
                                                                                value={el?.affiliation_services_id}
                                                                                className={`my-2 ${(!el?.affiliation_services_id && validated) && "rs-novalidated"}`}
                                                                                placeholder="Select Services"
                                                                            />
                                                                            {(!el?.affiliation_services_id && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Affiliation Services
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Ad Type</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={adType}
                                                                                block
                                                                                value={el?.ad_type_id}
                                                                                onChange={(e) => handleField(e, i, "ad_type_id")}
                                                                                className={`my-2 ${(!el?.ad_type_id && validated) && "rs-novalidated"}`}
                                                                                placeholder="Select Ad Type"
                                                                            />
                                                                            {(!el?.ad_type_id && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Ad Type
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>UTM Term</Form.Label>
                                                                            <CreatableSelect isClearable options={utmTerm}
                                                                                onChange={(e) => {
                                                                                    handleField(e?.value, i, "utm_term")
                                                                                }}
                                                                                placeholder="Select UTM Term"
                                                                                className={`customMulSelect my-2 ${(!el?.utm_term && validated) && "rs-novalidated"}`}
                                                                                classNamePrefix="react-select"
                                                                                value={{ label: el?.utm_term ? el?.utm_term : "", value: el?.utm_term ? el?.utm_term : "" }}
                                                                            />
                                                                            {(!el?.utm_term && validated) && <div className='rs-invalid-feedback'>
                                                                                Select UTM Term
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Lable</Form.Label>
                                                                            <SelectPicker
                                                                                cleanable={false}
                                                                                data={lable}
                                                                                block
                                                                                value={el?.lable}
                                                                                onChange={(e) => handleField(e, i, "lable")}
                                                                                className={`my-2 ${(!el?.lable && validated) && "rs-novalidated"}`}
                                                                                // menuClassName="menu-custom wv-200"
                                                                                placeholder="Select Lable"
                                                                            />
                                                                            {(!el?.lable && validated) && <div className='rs-invalid-feedback'>
                                                                                Select Lable
                                                                            </div>}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>URL</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="title"
                                                                                value={el?.url}
                                                                                onChange={(e) => handleField(e.target.value?.trimStart(), i, "url")}
                                                                                className="my-2"
                                                                                required
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Enter URL
                                                                            </Form.Control.Feedback>
                                                                            {/* {(!el?.url && validated) && <div className='text-danger'>
                                                                        
                                                                    </div>} */}
                                                                        </Col>
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Banner</Form.Label>
                                                                            <InputGroup className="my-2 input-file-choose-custome" >
                                                                                <Form.Control type="file"
                                                                                    className={`${((!el.banner && !selectedImage.affiliationImage[el.new_id || el.id]) && !el.affiliation_banner_image && validated) && "invalid-custom"}`}
                                                                                    onChange={(e) => {
                                                                                        handleImage(e, i, el?.new_id && el?.new_id, el?.id && el?.id);
                                                                                        //  handleField(e.target.files[0], i, "banner") 
                                                                                    }}
                                                                                    name="title" />
                                                                                {/* {...(!el?.banner&&!selectedImage.affiliationImage[el.new_id||el.id]  && { required: true })} /> */}
                                                                                <p>{selectedImage.affiliationImage[el.new_id || el?.id] ? selectedImage.affiliationImage[el.new_id || el?.id] : 'No file chosen'}</p>
                                                                                {((!el.banner && !selectedImage.affiliationImage[el.new_id || el.id]) && !el.affiliation_banner_image && validated) && <div className='rs-invalid-feedback'>
                                                                                    Select Banner
                                                                                </div>}
                                                                                <Fancybox>
                                                                                    <a href={el?.banner} data-fancybox="gallery">
                                                                                        <img src={el?.banner} className="hv-40 rounded-3" alt="" />
                                                                                    </a>
                                                                                </Fancybox>
                                                                            </InputGroup>
                                                                        </Col>
                                                                        {el?.ad_type_id && <Col lg={3} md={4} className="align-self-end">
                                                                            <div className="d-flex align-items-center mb-2">
                                                                                <Button variant="primary" onClick={() => getImages("", i, el?.ad_type_id, el.affiliation_banner_image, "is_other")}>Select Image</Button>
                                                                                <Fancybox>
                                                                                    <a href={el?.get_affilation_banner_image} data-fancybox="gallery">
                                                                                        <img src={el?.get_affilation_banner_image} className="hv-40 rounded-3 ms-3" alt="" />
                                                                                    </a>
                                                                                </Fancybox>
                                                                            </div>
                                                                        </Col>}
                                                                        <Col lg={3} md={4}>
                                                                            <Form.Label>Activity</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="title"
                                                                                value={el?.activity}
                                                                                onChange={(e) => handleField(e.target.value?.trimStart(), i, "activity")}
                                                                                className="my-2"
                                                                                placeholder="Enter Activity"
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Enter Activity
                                                                            </Form.Control.Feedback>
                                                                        </Col>
                                                                        <Col lg={1} md={4}>
                                                                            <Form.Label>Status</Form.Label>
                                                                            <Form.Check type="switch" className="my-2" onChange={(e) => handleField(e.target.checked, i, "status")} checked={el?.status == 1 ? true : false} />
                                                                        </Col>
                                                                        <Col lg={1} md={4}>
                                                                            <Form.Label>Priority</Form.Label>
                                                                            <Form.Check type="switch" className="my-2" onChange={(e) => handleField(e.target.checked, i, "is_priority")} checked={el?.is_priority == 1 ? true : false} />
                                                                        </Col>
                                                                        <Col lg={2} md={4}>
                                                                            <Form.Label>Is Deep Integration</Form.Label>
                                                                            <Form.Check type="switch" className="my-2" onChange={(e) => handleField(e.target.checked, i, "is_deep_integration")} checked={el?.is_deep_integration == 1 ? true : false} />
                                                                        </Col>
                                                                        {userPermission["Affilation-Program"]?.delete == 1 && <Col lg={1} md={4} className="align-self-end my-2 text-end">
                                                                            <Button variant="danger" size="sm" onClick={() =>
                                                                                handleDelete(i, el?.id && el.id, el.new_id && el.new_id)
                                                                            } className="btn-icon-lg ms-3"><i className='bx bx-x'></i></Button>
                                                                        </Col>}
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col></Form>)
                                            })}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                        {
                            !(affiliatiPlace == 19 && serviceCategory == "") &&
                            <Col sm={12}>
                                <Card>
                                    <Card.Body className="text-end">
                                        {(userPermission["Affilation-Program"]?.add == 1 || userPermission["Affilation-Program"]?.edit == 1) && <Button variant="primary" onClick={handleSave} className="me-3">Save</Button>}
                                        <Link to='/affiliation' className="btn btn-secondary">Cancel</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }

                    </Row>
                </div>
                <AffiliationImageModal
                    imageshow={imageshow}
                    ImagehandleClose={ImagehandleClose}
                    handleSelectImage={handleSelectImage}
                    selectedModalImage={selectedModalImage}
                    setSelectedModalImage={setSelectedModalImage}
                    languageImage={languageImage}
                    adType={adType}
                />
            </Layout>
        </>
    )
}

export default AffiliationAdd