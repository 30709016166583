import React, { useContext, useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Card, Row, Col, Button } from "react-bootstrap";
import SelectPicker from 'rsuite/SelectPicker';
import { Link, useNavigate, useParams } from "react-router-dom";
import { affilationProgramView, getAffiliatiPlace } from '../../service/apis';
import Drag from './AffiliationViewDrag';
import { AuthContext } from '../../AppContext';
import AffiliationGroupDrag from './AffiliationGroupDrag';

const AffiliationView = () => {
    const params = useParams()
    const { userPermission } = useContext(AuthContext)

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [categoryData, setcategoryData] = useState([]);
    const [affiliatiPlace, setAffiliatiPlace] = useState([]);
    const [programViewData, setProgramViewData] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState("");
    // const [selectedCategory, navigate]"/ = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()
    useEffect(() => {
        fetchAffiliatiPlace()
        setSelectedPlace(Number(params.id))
    }, [])
    useEffect(() => {
        selectedPlace && fetchAffilationProgramView(selectedPlace)
    }, [selectedPlace])

    useEffect(() => {
        if (programViewData?.is_category) {
            const filtered = programViewData?.data?.filter(
                (item) => item.category_id === selectedCategory
            );
            setFilteredData(filtered[0]?.data);
        } else {
            setFilteredData(programViewData?.data);
        }
    }, [selectedCategory, programViewData]);

    // Dropdown filter 
    const affiliatiPlaces = [];
    affiliatiPlace?.map((item) =>
        affiliatiPlaces.push({ label: item.place, value: item.id })
    );
    const handlePlaceChange = (e) => {
        setSelectedPlace(e)
        navigate(`/affiliation/view/${e}`)
        setSelectedCategory("")
    }

    //Fetch Api
    const fetchAffiliatiPlace = async () => {
        setIsLoading(true);
        try {
            const response = await getAffiliatiPlace()
            setAffiliatiPlace(response?.data?.data?.affiliation_place);
            setSelectedPlace(response?.data?.data?.affiliation_place?.find((item) => item.id == params.id).id)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchAffilationProgramView = async (id) => {
        setIsLoading(true);
        try {
            const response = await affilationProgramView({ place_id: id })
            if (response?.data?.is_category) {
                const categories = response?.data?.data?.map((item) => ({
                    label: item?.category_name,
                    value: item?.category_id
                }));
                setcategoryData(categories);
                setProgramViewData(response?.data);

                if (categories.length > 0) {
                    setSelectedCategory(categories[0].value);
                }
            } else {
                setProgramViewData(response?.data);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const getData = async () => {
        const response = await affilationProgramView({ place_id: selectedPlace })
        setProgramViewData(response?.data);
    }

    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading backbtn">
                    <h3><Link to="/affiliation" className='btn btn-transparent btn-icon me-2'><i className='bx bx-chevron-left'></i></Link>Affiliation Program View</h3>
                    <div className="page-heading-right">
                        {programViewData?.is_category && <SelectPicker
                            cleanable={false}
                            data={categoryData}
                            onChange={(e) => {
                                setSelectedCategory(e)
                            }}
                            value={selectedCategory}
                            block
                            className="wv-200 my-1 ms-3"
                            placeholder={`Select Service Category`}
                            placement='bottomEnd'
                        />}
                        <SelectPicker
                            cleanable={false}
                            value={selectedPlace}
                            onChange={handlePlaceChange}
                            data={affiliatiPlaces}
                            className="wv-200 my-1 ms-3"
                            placeholder="Select Place"
                            placement="bottomEnd"
                        />
                        {userPermission["Affilation-Program"]?.edit == 1 && <Link to="/affiliation/edit" className="btn btn-primary ms-3 my-1"
                            state={{ placed: selectedPlace, category: "" }}
                        >Edit</Link>}
                        {/* <Button variant="danger ms-3 my-1 btn-icon-lg" type="button"><i className="bx bx-trash-alt"></i></Button> */}
                    </div>
                </div>
                <div className='page-content'>
                    <Card className="radiustop-0">
                        <Card.Body>
                            {(programViewData?.is_category == 1 || programViewData?.is_group) ? <Row className="g-4">
                                {filteredData?.length > 0 &&
                                    <AffiliationGroupDrag programViewData={filteredData} selectedPlace={selectedPlace} parentCallback={() => getData()} selectedCategory={selectedCategory} />
                                    // programViewData?.data?.map((item) => {
                                    //     return (
                                    //         <Col sm={12}>
                                    //             <Card className="border">
                                    //                 <Card.Header className="border-bottom d-flex align-items-center justify-content-between">
                                    //                     <h5 className="m-0 fw-bold">{item?.get_name?.category ? item?.get_name?.category : `Group-${item?.group_id}`}</h5>
                                    //                     {userPermission["Affilation-Program"]?.edit==1&& <Link className="btn btn-primary btn-icon" to="/affiliation/edit" 
                                    //                         state= {{
                                    //                             placed: selectedPlace,
                                    //                             category: item?.service_category_id,
                                    //                         }}
                                    //                     ><i className="bx bx-pencil"></i></Link>}
                                    //             </Card.Header>
                                    //             <Card.Body>
                                    //                 <Drag programViewData={item?.affilation_data_list} placeName={programViewData?.place_name} parentCallback={() => getData()} affilationDataList={programViewData?.data}  affilateId={item?.id}/>
                                    //             </Card.Body>
                                    //         </Card>
                                    //         </Col>
                                    //     )
                                    // })
                                }
                            </Row> :
                                <Drag programViewData={programViewData?.data} placeName={programViewData?.place_name} parentCallback={() => getData()} affilationDataList={programViewData?.data} affilateId={null} />
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Layout >
        </>
    )
}

export default AffiliationView