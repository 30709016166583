import '@dotlottie/react-player/dist/index.css';
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SelectPicker from 'rsuite/SelectPicker';
import { AuthContext } from "../../AppContext";
import AddAffilation from "../../Component/AddAffilation";
import SubplaceAdd from "../../Component/AddPlace";
import Layout from "../../layout/Layout";
import { AddAffilationInsurance, getAffilationDropdown, getAffilationInsurance, getInsuranceServices, getMakeModalDropDown } from "../../service/apis";

let serviceCategoryData = []
let isBikeOrCar = "";

const AffiliationInsurance = () => {
    const { userPermission } = useContext(AuthContext)

    const [allAffilationData, setAllAffilationData] = useState([]);
    const [allFieldDropdown, setAllFieldDropdown] = useState(null);
    const [serviceCategory, setServiceCategory] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [deletedInsuranceId, setDeletedInsuranceId] = useState([])
    const [validated, setValidated] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false);
    const [modalDropDown, setmodalDropDown] = useState([]);
    const [makerDropDown, setmakerDropDown] = useState([]);

    useEffect(() => {
        fetchDynamicDropdown()
        fetchAffilationDropdown()
    }, [])

    const getMakemodalDropDownBikeList = async (vehicleCategoryId) => {
        try {
            const response = await getMakeModalDropDown(vehicleCategoryId);
            const modal = response?.data?.data?.models?.map((item) => {
                return { value: item?.id, label: item?.name };
            });
            const makers = response?.data?.data?.makers?.map((item) => {
                return { value: item?.id, label: item?.name };
            });

            setmodalDropDown(modal)
            setmakerDropDown(makers)
        } catch (err) {
            console.log(err, "error in dropdown list fetch");
        }
    }

    // change values......
    const handleTestOnChange = (value, index, field, nestedField = null, rangeNumber = null, nestedIndex) => {
        setAllAffilationData((prevData) => {
            return prevData?.map((item, i) => {
                if (i == index) {
                    if (nestedField) {
                        if (rangeNumber) {
                            let currentRange = (item[field]?.split(',')?.length > 1 && item[field]?.includes("-")) ? item[field]?.split(',') : ['-'];

                            if (currentRange[nestedIndex]) {
                                let subRange = currentRange[nestedIndex].split('-');
                                subRange[rangeNumber - 1] = value;
                                currentRange[nestedIndex] = subRange.join('-');
                            }
                            if (currentRange?.length == 1) {
                                return {
                                    ...item,
                                    [field]: (currentRange?.toString() + ",")?.split('+')[0] + ((currentRange?.toString() + ",")?.includes('+') ? '+-,' : '')
                                };
                            } else {
                                return {
                                    ...item,
                                    [field]: currentRange.join(',')?.split('+')[0] + (currentRange.join(',')?.includes('+') ? '+-,' : '')
                                };
                            }
                        }
                        else {
                            return {
                                ...item,
                                [field]: {
                                    ...item[field],
                                    [nestedField]: value,
                                },
                            };
                        }

                        return item;
                    }
                    else if (field == 'city_id_list' || field == 'vehicle_type' || field == 'manufuring_year' || field == 'previous_insurer') {
                        const array_value = value?.map(item => item.value)?.join(',') || ''
                        return {
                            ...item,
                            [field]: array_value
                        }
                    }
                    else if (field == 'status') {
                        return {
                            ...item,
                            [field]: value ? 1 : 0
                        }
                    }
                    // else if (field == 'is_specify_city' || field == 'is_allCity') {
                    //     if (field == 'is_specify_city') {
                    //         return {
                    //             ...item,
                    //             city_id_list: value ? "" : "All",
                    //             blocked_pincodevalue: value ? item?.blocked_pincodevalue : ''
                    //         }
                    //     }
                    //     else {
                    //         return {
                    //             ...item,
                    //             city_id_list: value ? "All" : '',
                    //             blocked_pincode: value ? '' : item?.blocked_pincode
                    //         }
                    //     }
                    // }

                    else if (field == "blocked_pincode") {
                        const input = value;
                        const regex = /^[0-9,]+$/;
                        if (regex.test(input) || input === "") {
                            const pin = input?.replaceAll(",,", ",")?.replaceAll(" ", "");
                            return {
                                ...item,
                                [field]: pin,
                            };
                        } else {
                            console.error("Invalid input: Only numbers and commas are allowed.");
                            return item;
                        }
                    }
                    return {
                        ...item,
                        [field]: value,
                    };
                }
                return item;
            });
        });

    };

    //Fetch Api
    const fetchDynamicDropdown = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData()
            formData?.append("is_insurance", 1)
            const response = await getInsuranceServices(formData)
            serviceCategoryData = response?.data?.data?.map((e) => ({ label: e?.name, value: e?.id }))

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchAffilationDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getAffilationDropdown()
            setAllFieldDropdown(response?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchAffilationData = async (payload) => {
        setIsLoading(true);
        try {
            const response = await getAffilationInsurance(payload)
            setAllAffilationData(response?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const AddNewAffilationData = async (payload) => {
        setIsLoading(true);
        try {
            const response = await AddAffilationInsurance(payload)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    //Add new Affilation
    const addNewInsuranceAffilation = () => {
        const addAffilation = Array.isArray(allAffilationData) ? [...allAffilationData] : [];

        addAffilation.unshift({
            service_provider: "",
            priority: "",
            services: "",
            vehicle_age_range: "-",
            expiry_days_range: "-",
            expired_days_range: "-",
            vehicle_type: '',
            maker: '',
            model: '',
            manufacturing_year: '',
            status: 0,
            // city_id_list: 'All',
            // state_list: '',
            rto_code: '',
            blocked_pincode: '',
            previous_insurer: "",
            key_priority: 'vehicle_age_range,expiry_days_range,expired_days_range,maker,model,city,pincode,state,manufuring_year,previous_insurer,rtoCode',
        });

        setAllAffilationData(addAffilation);
    };

    //Delete affilation
    const handleDelete = (index, id, newId) => {
        if (id) {
            setDeletedInsuranceId([...deletedInsuranceId, id])
        }
        allAffilationData?.length > 0 && setAllAffilationData(allAffilationData?.filter((item, i) => i != index))
    }

    const validate = () => {
        const checkVehicleRange = (ranges) => {
            return ranges?.split(",").some((range, i) => {
                if (!range || range === '-') return false;
                const [min, max] = range.split("-");
                if (min && !max && min.includes("+")) return false;
                if ((!min && max) || (min && !max)) {
                    return true;
                }
                if (+min >= Number(max)) {
                    return true;
                }
                if (ranges && i != 0 && (Number(ranges?.split(",")[i - 1]?.split("-")[1]) > Number(min))) {
                    return true;
                }
                return false;
            });
        };
        const isValidate = allAffilationData.some(el => !el.service_provider || !el.priority || el.priority == "0" || +el.priority > allAffilationData?.length || checkVehicleRange(el?.vehicle_age_range) || checkVehicleRange(el?.expired_days_range) || checkVehicleRange(el?.expiry_days_range))
        return isValidate

    };

    const handleSave = async () => {
        // setIsLoading(true)
        setValidated(true)
        if (!validate()) {
            setValidated(false)

            const formData = new FormData()
            formData?.append("service_id", serviceCategory)
            formData?.append("is_insurance", 1)
            formData?.append("data", JSON.stringify(allAffilationData))
            deletedInsuranceId?.length > 0 && formData?.append("deleted_data", JSON.stringify(deletedInsuranceId))

            AddNewAffilationData(formData).then(async (res) => {

                await fetchAffilationData({ is_insurance: 1, service_id: serviceCategory });
                setIsSubmited(!isSubmited)

            })
            toast.success("Affiliation Insurance update successfully!!")

        } else { setValidated(true) }
    }
    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading backbtn">
                    <h3><Link to="/" className='btn btn-transparent btn-icon me-2'><i className='bx bx-chevron-left'></i></Link>{"Affiliation Insurance Program"}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{"Affiliation Insurance Program Add"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Row className="g-4">
                        <Col sm={12}>
                            <Card className="radiustop-0">
                                <Card.Body>
                                    <Row>
                                        <Col lg={3} md={4}>
                                            <Form.Label>Insurance Service</Form.Label>
                                            <SelectPicker
                                                cleanable={false}
                                                data={serviceCategoryData}
                                                value={serviceCategory}
                                                onChange={(e, el) => {
                                                    setDeletedInsuranceId([])
                                                    setServiceCategory(e)
                                                    const selectedItem = serviceCategoryData.find(item => item.value == e);
                                                    isBikeOrCar = selectedItem?.label?.toLowerCase()?.includes('bike') ? 1 : 2;
                                                    getMakemodalDropDownBikeList(isBikeOrCar)
                                                    fetchAffilationData({ service_id: e, is_insurance: 1 })
                                                }}
                                                block
                                                className="my-2"
                                                placeholder={`Select Insurance service`}
                                            />
                                        </Col>
                                        {
                                            serviceCategory && userPermission["Affilation-Program"]?.add == 1 &&
                                            <Col lg={3} md={4} className="align-self-end my-2">
                                                <Button onClick={addNewInsuranceAffilation} variant="primary">Add New</Button>
                                            </Col>
                                        }
                                        {(allAffilationData?.length > 0 || deletedInsuranceId?.length > 0) &&
                                            <Col lg={6} md={4} className="align-self-end my-2 text-end">
                                                {(userPermission["Affilation-Program"]?.add == 1 || userPermission["Affilation-Program"]?.edit == 1) && <Button variant="primary" onClick={handleSave} className="me-3">Save</Button>}
                                                <Link to='/' className="btn btn-secondary">Cancel</Link>
                                            </Col>
                                        }
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        {allAffilationData?.length > 0 && <Col sm={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="g-4">
                                        {/* Affilition New */}
                                        <AddAffilation
                                            handleTestOnChange={handleTestOnChange}
                                            allAffilationData={allAffilationData}
                                            handleDelete={handleDelete}
                                            setValidated={setValidated}
                                            validated={validated}
                                            setAllAffilationData={setAllAffilationData}
                                            isSubmited={isSubmited}
                                            makerDropDown={makerDropDown}
                                            modalDropDown={modalDropDown}
                                        />

                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        }




                    </Row>
                </div>
            </Layout>
        </>
    )
}

export default AffiliationInsurance