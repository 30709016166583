import '@dotlottie/react-player/dist/index.css';
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SelectPicker from 'rsuite/SelectPicker';
import { AuthContext } from "../../AppContext";
import AddAffilation from "../../Component/AddAffilation";
import SubplaceAdd from "../../Component/AddPlace";
import Layout from "../../layout/Layout";
import { AddAffilationInsurance, getAffilationDropdown, getAffilationInsurance, getInsuranceServices, getMakeModalDropDown } from "../../service/apis";

let serviceCategoryData = []
let isBikeOrCar = "";

const AffiliationPlace = () => {
      const { userPermission } = useContext(AuthContext)

      const [allAffilationData, setAllAffilationData] = useState([]);
      const [allFieldDropdown, setAllFieldDropdown] = useState(null);
      const [serviceCategory, setServiceCategory] = useState("");
      const [isLoading, setIsLoading] = useState(false);
      const [deletedInsuranceId, setDeletedInsuranceId] = useState([])
      const [validated, setValidated] = useState(false)
      const [isSubmited, setIsSubmited] = useState(false);
      const [modalDropDown, setmodalDropDown] = useState([]);
      const [makerDropDown, setmakerDropDown] = useState([]);
      const idsToRemove = new Set(allAffilationData?.map(item => item?.affiliation_place_id));
      const filteredRecords = allFieldDropdown?.affilation_place_data?.filter(record => !idsToRemove.has(record.id));
      useEffect(() => {
            fetchDynamicDropdown()
            fetchAffilationDropdown()
      }, [])

      const getMakemodalDropDownBikeList = async (vehicleCategoryId) => {
            try {
                  const response = await getMakeModalDropDown(vehicleCategoryId);
                  const modal = response?.data?.data?.models?.map((item) => {
                        return { value: item?.id, label: item?.name };
                  });
                  const makers = response?.data?.data?.makers?.map((item) => {
                        return { value: item?.id, label: item?.name };
                  });

                  setmodalDropDown(modal)
                  setmakerDropDown(makers)
            } catch (err) {
                  console.log(err, "error in dropdown list fetch");
            }
      }

      // change values......
      const handleTestOnChange = (value, index, field, nestedField = null, rangeNumber = null) => {
            setAllAffilationData((prevData) => {
                  return prevData?.map((item, i) => {
                        if (i == index) {
                              if (nestedField) {
                                    if (/^\d*$/.test(value)) {
                                          if (rangeNumber) {
                                                const currentRange = item[field]?.[nestedField]?.split('-') || ['', ''];
                                                currentRange[rangeNumber - 1] = value;
                                                return {
                                                      ...item,
                                                      [field]: {
                                                            ...item[field],
                                                            [nestedField]: currentRange.join('-'),
                                                      },
                                                };
                                          }
                                          else {
                                                return {
                                                      ...item,
                                                      [field]: {
                                                            ...item[field],
                                                            [nestedField]: value,
                                                      },
                                                };
                                          }
                                    }
                                    return item;
                              }

                              else if (field == 'city_id_list' || field == 'vehicle_type' || field == 'manufuring_year' || field == 'city_id_list') {
                                    const array_value = value?.map(item => item.value)?.join(',') || ''
                                    return {
                                          ...item,
                                          [field]: array_value
                                    }
                              }
                              else if (field == 'status') {
                                    return {
                                          ...item,
                                          [field]: value ? 1 : 0
                                    }
                              }
                              else if (field == 'is_specify_city' || field == 'is_allCity') {
                                    if (field == 'is_specify_city') {
                                          return {
                                                ...item,
                                                city_id_list: value ? "" : "All",
                                                blocked_pincodevalue: value ? item?.blocked_pincodevalue : ''
                                          }
                                    }
                                    else {
                                          return {
                                                ...item,
                                                city_id_list: value ? "All" : '',
                                                blocked_pincode: value ? '' : item?.blocked_pincode
                                          }
                                    }
                              }

                              else if (field == "blocked_pincode") {
                                    const input = value;
                                    const regex = /^[0-9,]+$/;
                                    if (regex.test(input) || input === "") {
                                          const pin = input?.replaceAll(",,", ",")?.replaceAll(" ", "");
                                          return {
                                                ...item,
                                                [field]: pin,
                                          };
                                    } else {
                                          console.error("Invalid input: Only numbers and commas are allowed.");
                                          return item;
                                    }
                              }
                              return {
                                    ...item,
                                    [field]: value,
                              };
                        }
                        return item;
                  });
            });

      };

      //Fetch Api
      const fetchDynamicDropdown = async () => {
            setIsLoading(true);
            try {
                  const formData = new FormData()
                  formData?.append("is_insurance", 0)
                  const response = await getInsuranceServices(formData)
                  serviceCategoryData = response?.data?.data?.map((e) => ({ label: e?.name, value: e?.id }))

            } catch (error) {
                  console.log(error)
            } finally {
                  setIsLoading(false);
            }
      }
      const fetchAffilationDropdown = async () => {
            setIsLoading(true);
            try {
                  const response = await getAffilationDropdown()
                  setAllFieldDropdown(response?.data?.data);
            } catch (error) {
                  console.log(error)
            } finally {
                  setIsLoading(false);
            }
      }
      const fetchAffilationData = async (payload) => {
            setIsLoading(true);
            try {
                  const response = await getAffilationInsurance(payload)
                  setAllAffilationData(response?.data?.data);
            } catch (error) {
                  console.log(error)
            } finally {
                  setIsLoading(false);
            }
      }

      const AddNewAffilationData = async (payload) => {
            setIsLoading(true);
            try {
                  const response = await AddAffilationInsurance(payload)
            } catch (error) {
                  console.log(error)
            } finally {
                  setIsLoading(false);
            }
      }

      //Add new Affilation
      const addAffilationInsurancePlace = () => {
            const addAffilation = Array.isArray(allAffilationData) ? [...allAffilationData] : [];

            addAffilation.unshift({
                  affiliation_place_id: '',
                  data: [{
                        service_provider_id: "",
                        ad_type_id: "",
                        utm_term: "",
                        lable: "",
                        url: "",
                        banner: "",
                        status: 0
                  }]
            })

            setAllAffilationData(addAffilation);
      };

      const handleSubPLaceAdd = (index) => {
            let allSubPlaceArray = [...allAffilationData[index]?.data]
            allSubPlaceArray.push({
                  service_provider_id: "",
                  ad_type_id: "",
                  utm_term: "",
                  lable: "",
                  url: "",
                  banner: "",
                  status: 0
            })

            setAllAffilationData(prev => prev.map((ele, ind) => {
                  if (index == ind) {
                        return {
                              ...ele,
                              data: allSubPlaceArray
                        }
                  }
                  else {
                        return ele
                  }
            }))
      }

      //Delete affilation
      const handleDelete = (index, id) => {
            if (id) {
                  setDeletedInsuranceId([...deletedInsuranceId, ...id])
            }
            allAffilationData?.length > 0 && setAllAffilationData(allAffilationData?.filter((item, i) => i != index))
      }

      const validate = () => {

            return allAffilationData?.some(item =>
                  item?.data?.some(el =>
                        !el.service_provider_id ||
                        !el.ad_type_id ||
                        !el.utm_term ||
                        !el.lable ||
                        !el.url ||
                        !el.affiliation_banner_image
                  ) || !item?.affiliation_place_id
            );
      };


      const handleSave = async () => {
            // setIsLoading(true)
            setValidated(true)
            if (!validate()) {
                  setValidated(false)
                  const formData = new FormData()
                  formData?.append("service_id", serviceCategory)
                  formData?.append("is_insurance", 0)
                  formData?.append("data", JSON.stringify(allAffilationData))
                  deletedInsuranceId?.length > 0 && formData?.append("deleted_data", JSON.stringify(deletedInsuranceId))

                  AddNewAffilationData(formData).then(async (res) => {
                        setDeletedInsuranceId([])
                        await fetchAffilationData({ is_insurance: 0, service_id: serviceCategory });
                        setIsSubmited(!isSubmited)

                  })
                  toast.success("Affiliation Insurance update successfully!!")

            } else { setValidated(true) }
      }


      return (
            <>
                  <Layout sidebar={true}>
                        {isLoading && <div className="loader" ></div>}
                        <div className="page-heading backbtn">
                              <h3><Link to="/" className='btn btn-transparent btn-icon me-2'><i className='bx bx-chevron-left'></i></Link>{"Affiliation Insurance Place"}</h3>
                              <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                                    <Breadcrumb.Item >
                                          <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>{"Affiliation Insurance Program Add"}</Breadcrumb.Item>
                              </Breadcrumb>
                        </div>
                        <div className="page-content">
                              <Row className="g-4">
                                    <Col sm={12}>
                                          <Card className="radiustop-0">
                                                <Card.Body>
                                                      <Row>
                                                            <Col lg={3} md={4}>
                                                                  <Form.Label>Insurance Service</Form.Label>
                                                                  <SelectPicker
                                                                        cleanable={false}
                                                                        data={serviceCategoryData}
                                                                        value={serviceCategory}
                                                                        onChange={(e, el) => {
                                                                              setDeletedInsuranceId([])
                                                                              setServiceCategory(e)
                                                                              fetchAffilationData({ service_id: e, is_insurance: 0 })
                                                                        }}
                                                                        block
                                                                        className="my-2"
                                                                        placeholder={`Select Insurance service`}
                                                                  />
                                                            </Col>
                                                            {
                                                                  allAffilationData?.length < allFieldDropdown?.affilation_place_data?.length
                                                                  && ((allAffilationData?.map(item => item?.affiliation_place_id)?.length <= 0) || filteredRecords?.length > 0)
                                                                  && serviceCategory
                                                                  && userPermission["Affilation-Program"]?.add == 1 &&
                                                                  <Col lg={3} md={4} className="align-self-end my-2">
                                                                        <Button onClick={addAffilationInsurancePlace} variant="primary">Add New</Button>
                                                                  </Col>
                                                            }
                                                            {(allAffilationData?.length > 0 || deletedInsuranceId?.length > 0) &&
                                                                  <Col lg={6} md={4} className="align-self-end my-2 text-end">
                                                                        {(userPermission["Affilation-Program"]?.add == 1 || userPermission["Affilation-Program"]?.edit == 1) && <Button variant="primary" onClick={handleSave} className="me-3">Save</Button>}
                                                                        <Link to='/' className="btn btn-secondary">Cancel</Link>

                                                                  </Col>}
                                                      </Row>
                                                </Card.Body>
                                          </Card>
                                    </Col>
                                    <Col sm={12}>
                                          {/* <Card>
                                                <Card.Body> */}
                                          {/* Affilition New */}
                                          {/* <AddAffilation
                                            handleTestOnChange={handleTestOnChange}
                                            handleDelete={handleDelete}
                                            setValidated={setValidated}
                                            validated={validated}
                                            isSubmited={isSubmited}
                                            makerDropDown={makerDropDown}
                                            modalDropDown={modalDropDown}
                                            /> */}

                                          <SubplaceAdd
                                                allAffilationData={allAffilationData}
                                                setAllAffilationData={setAllAffilationData}
                                                handleSubPLaceAdd={handleSubPLaceAdd}
                                                handleDelete={handleDelete}
                                                validated={validated}
                                                setDeletedInsuranceId={setDeletedInsuranceId}
                                                allFieldDropdown={allFieldDropdown}
                                          />

                                          {/* </Card.Body>
                                          </Card> */}
                                    </Col>
                              </Row>
                        </div>
                  </Layout>
            </>
      )
}

export default AffiliationPlace