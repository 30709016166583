import { createContext, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import './style/bootstrap.css';
import 'boxicons/css/boxicons.css';
import 'bootstrap-daterangepicker/daterangepicker.css'
import './style/App.css';
import './style/utilities.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';

import AffiliationDashboard from './pages/AffiliationDashboard/AffiliationDashboard.jsx';
import AffiliationTracker from './pages/AffiliationTracker/AffiliationTracker.jsx';
import TrackerPerformance from './pages/AffiliationTracker/TrackerPerformance.jsx';

import Affiliation from './pages/Affiliation/Affiliation';
import AffiliationAdd from './pages/Affiliation/AffiliationAdd';
import AffiliationData from './pages/Affiliation/AffiliationData';
import AffiliationLanguage from './pages/Affiliation/AffiliationLanguage';
import AffiliationOffer from './pages/Affiliation/AffiliationOffer';
import AffiliationView from './pages/Affiliation/AffiliationView';

import AffiliationCity from './pages/AffiliationCity/AffiliationCity';
import AffiliationCityAdd from './pages/AffiliationCity/AffiliationCityAdd';
import AffiliationCityView from './pages/AffiliationCity/AffiliationCityView';

import ServiceProviderCity from './pages/ServiceProviderCity/ServiceProviderCity';
import ServiceProviderCityAdd from './pages/ServiceProviderCity/ServiceProviderCityAdd';
import ServiceProviderCityView from './pages/ServiceProviderCity/ServiceProviderCityView';

import ChallanServiceProvider from './pages/ChallanServiceProvider/ChallanServiceProvider.jsx';
import ChallanServiceStateAdd from './pages/ChallanServiceProvider/ChallanServiceStateAdd.jsx';
import ChallanServiceProviderStateView from './pages/ChallanServiceProvider/ChallanServiceProviderStateView.jsx'


import { AuthContext } from './AppContext.jsx';

import Module from "./pages/Module/Module"
import AddModule from "./pages/Module/ModuleAdd"
import ViewModule from "./pages/Module/ModuleView"
import EditModule from "./pages/Module/ModuleEdit"

import Role from './pages/Role/Role';
import RoleAdd from './pages/Role/RoleAdd';
import ViewRole from './pages/Role/RoleView';

import User from './pages/Permission/User';
import AddUser from './pages/Permission/UserAdd';
import ViewUser from './pages/Permission/UserView';
import EditUser from './pages/Permission/UserEdit';
import ChangeUrl from './pages/ChangeUrl/ChangeUrl.jsx'
import AddImageForm from './pages/Affiliation/AddImageForm.jsx';
import Protected from './Component/Protected.js';
import PrivetRoutes from './Component/PrivetRoutes.js';
import AffiliationInsurance from './pages/AffilationInsurance/AffilationInsurance.jsx';
import AffiliationPlace from './pages/AffilationInsurance/AffiliationPlace.jsx';

export const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

const App = () => {
    const { userPermission, theme, setTheme } = useContext(AuthContext)

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<Protected />}>
                        <Route path="/" element={<Login />} />
                    </Route>
                    <Route element={<PrivetRoutes />}>
                        <Route path="/Home" element={<Home />} />
                        <Route path="*" element={<PageNotFound />} />

                        {userPermission["Affilation-Dashboard"]?.view == 1 &&
                            <Route path="/affiliation-dashboard" element={<AffiliationDashboard />} />
                        }

                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation" element={<Affiliation />} />
                        }
                        {userPermission["Affilation-Program"]?.add == 1 &&
                            <Route path="/affiliation/add" element={<AffiliationAdd />} />
                        }
                        {userPermission["Affilation-Program"]?.edit == 1 &&
                            <Route path="/affiliation/edit" element={<AffiliationAdd />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation/view" element={<AffiliationView />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation/view/:id" element={<AffiliationView />} />
                        }
                        {userPermission["Affilation-Data"]?.view == 1 &&
                            <Route path="/affiliation/data" element={<AffiliationData />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation/offer" element={<AffiliationOffer />} />
                        }

                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation-insurance" element={<AffiliationInsurance />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation-Place" element={<AffiliationPlace />} />
                        }


                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/change-url" element={<ChangeUrl />} />
                        }
                        {userPermission["Language"]?.view == 1 &&
                            <Route path="/affiliation/language" element={<AffiliationLanguage />} />
                        }
                        {userPermission["Affilation-Image"]?.view == 1 &&
                            <Route path="/affiliation/addimages" element={<AddImageForm />} />
                        }
                        {userPermission["Service-City"]?.view == 1 &&
                            <Route path="affiliation-city" element={<AffiliationCity />} />
                        }
                        {userPermission["Service-City"]?.add == 1 &&
                            <Route path="affiliation-city/add" element={<AffiliationCityAdd />} />
                        }
                        {userPermission["Service-City"]?.edit == 1 &&
                            <Route path="affiliation-city/edit/:id" element={<AffiliationCityAdd />} />
                        }
                        {userPermission["Service-City"]?.view == 1 &&
                            <Route path="affiliation-city/view" element={<AffiliationCityView />} />
                        }
                        {userPermission["Service-City"]?.view == 1 &&
                            <Route path="affiliation-city/view/:id" element={<AffiliationCityView />} />
                        }

                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="service-provider-city" element={<ServiceProviderCity />} />
                        }
                        {userPermission["Service-Provider"]?.add == 1 &&
                            <Route path="service-provider-city/add" element={<ServiceProviderCityAdd />} />
                        }
                        {userPermission["Service-Provider"]?.edit == 1 &&
                            <Route path="service-provider-city/edit/:id" element={<ServiceProviderCityAdd />} />
                        }
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="service-provider-city/view" element={<ServiceProviderCityView />} />
                        }
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="service-provider-city/view/:id" element={<ServiceProviderCityView />} />
                        }
                        {/* challan */}
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="affilation-challan" element={<ChallanServiceProvider />} />
                        }
                        {userPermission["Service-Provider"]?.add == 1 &&
                            <Route path="affilation-challan-state/add" element={<ChallanServiceStateAdd />} />
                        }
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="affilation-challan-state/view/:id" element={<ChallanServiceProviderStateView />} />
                        }
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="affilation-challan-state/edit/:id" element={<ChallanServiceStateAdd />} />
                        }

                        {userPermission["Affiliation-Clicks"]?.view == 1 && <Route path="/affiliation-tracker" element={<AffiliationTracker />} />}
                        {userPermission["Affiliation-Performance"]?.view == 1 && <Route path="/tracker-performance" element={<TrackerPerformance />} />}

                        <>

                            <Route path="/module" element={<Module />} />
                            <Route path="/module/add" element={<AddModule />} />
                            <Route path="/module/view/:id" element={<ViewModule />} />
                            <Route path="/module/edit/:id" element={<EditModule />} />

                            <Route path="/role" element={<Role />} />
                            <Route path="/role/add" element={<RoleAdd />} />
                            <Route path="/role/edit/:id" element={<RoleAdd />} />
                            <Route path="/role/view/:id" element={<ViewRole />} />

                            <Route path="/user" element={<User />} />
                            <Route path="/user/add" element={<AddUser />} />
                            <Route path="/user/view/:id" element={<ViewUser />} />
                            <Route path="/user/edit/:id" element={<EditUser />} />
                        </>

                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer position='bottom-right' autoClose={500} />
        </>
    )
}

export default App;
