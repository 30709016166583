import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { SelectPicker } from 'rsuite'
import CreatableSelect from 'react-select/creatable';
import { AuthContext } from '../AppContext';
import Fancybox from './FancyBox';
import AffiliationImageModal from './AffiliationImageModal';
import { getLanguageImage } from '../service/apis';


function SubplaceAdd({
    allAffilationData,
    setAllAffilationData,
    validated,
    allFieldDropdown,
    handleSubPLaceAdd,
    handleDelete,
    setDeletedInsuranceId
}) {
    const [imageshow, setImageShow] = useState(false);
    const { userPermission } = useContext(AuthContext);
    const [affilationDropdown, setAffilationDropdown] = useState({});
    const [subPlaceData, setSubPlaceData] = useState([]);
    const [languageImage, setLanguageImage] = useState({ data: [], index: null, i: null, image: "", groupId: "", key: "", adtype_id: "" });
    const [selectedModalImage, setSelectedModalImage] = useState({ id: "", url: "" })

    const deleteMainPlace = (index) => {
        const updatedArray = [...subPlaceData];
        updatedArray?.splice(index, 1);
        setSubPlaceData(updatedArray);
    }
    const deleteSubPlace = (index, subIndex, id) => {
        let updatedArray = [...allAffilationData];
        let subPlace = [...updatedArray[index]?.data]
        subPlace?.splice(subIndex, 1);
        updatedArray[index].data = subPlace;
        setAllAffilationData(updatedArray);
        if (id) {
            setDeletedInsuranceId((prevState) => [...prevState, id]);        }
    }


    const ImagehandleClose = () => setImageShow(false);

    const handleSelectImage = () => {
        const e = selectedModalImage?.id
        const { index, i, } = languageImage

        setAllAffilationData((prevData) => {
            return prevData.map((affiliation, affIndex) => {
                if (affIndex === index) {
                    return {
                        ...affiliation,
                        data: affiliation.data.map((item, itemIndex) => {
                            if (itemIndex === i) {
                                return {
                                    ...item,
                                    affiliation_banner_image: e === true ? 1 : e === false ? 0 : e ?? null
                                };
                            }
                            return item;
                        })
                    };
                }
                return affiliation;
            });
        });
        ImagehandleClose()
    };

    const getImages = async (index, i, e, imageId, key) => {
        const res = await getLanguageImage({ adtype_id: e });
        setLanguageImage({ data: res?.data?.data, index, i, image: imageId, key, adtype_id: e });
        setSelectedModalImage({ id: imageId, url: "" });
        setImageShow(true)
    }

    const handleOnChange = (value, index, subIndex, field) => {
        setAllAffilationData(prev => prev?.map((ele, ind) => {
            if (subIndex == -1) {
                if (index == ind) {
                    let sub_placeses = ele;
                    sub_placeses[field] = value

                    return {
                        ...ele, ...sub_placeses
                    }
                }
                else {
                    return ele
                }
            } else {
                if (index == ind) {
                    let sub_placeses = [...ele?.data];
                    sub_placeses[subIndex] = {
                        ...sub_placeses[subIndex],
                        [field]: value === true ? 1 : value === false ? 0 : value ?? null
                    }

                    return {
                        ...ele,
                        data: sub_placeses
                    }
                }
                else {
                    return ele
                }
            }
        }))
    }

    useEffect(() => {
        const idsToRemove = new Set(allAffilationData?.map(item => item?.affiliation_place_id));
        const filteredRecords = allFieldDropdown?.affilation_place_data.filter(record => !idsToRemove.has(record.id));
        const place = [];
        filteredRecords?.length > 0 && filteredRecords?.map((item) =>
            place.push({ label: item?.name, value: item?.id })
        );
        const lable = [];
        allFieldDropdown && allFieldDropdown?.lable?.map((item) =>
            lable.push({ label: item.lable, value: item.lable })
        );
        const serviceProvider = [];
        allFieldDropdown && allFieldDropdown?.service_provider?.map((item) =>
            serviceProvider.push({ label: item.provider, value: item.id })
        );
        const affilationservice = [];
        allFieldDropdown && allFieldDropdown?.affilation_service?.map((item) =>
            affilationservice.push({ label: item.services, value: item.id })
        );
        const adType = [];
        allFieldDropdown && allFieldDropdown?.ad_type?.map((item) =>
            adType.push({ label: item.type, value: item.id })
        );
        const utmTerm = [];
        allFieldDropdown?.utm_term?.length > 0 && allFieldDropdown?.utm_term?.map((item) =>
            utmTerm.push({ label: item, value: item })
        );


        setAffilationDropdown({
            lable,
            serviceProvider,
            adType,
            utmTerm,
            place
        })
    }, [allFieldDropdown, allAffilationData])
    return (
        <>
            <Row className="g-4">
                {/* <Col sm={12}>
                    <Card className="radiustop-0">
                        <Card.Body>
                            <Row>
                                {userPermission["Affilation-Program"]?.add == 1 &&
                                    <Col lg={3} md={4} className="align-self-end my-2 text-end">
                                        <Button onClick={handleAddMainSubPlace} variant="primary">Add Place</Button>
                                    </Col>}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col> */}
                {allAffilationData?.length > 0 && allAffilationData?.map((element, index) => (
                    <Col sm={12}>
                        <Card className="border">
                            <Card.Header className="border-bottom">
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <Col lg={3} md={4}>
                                        <Form.Label>Place</Form.Label>
                                        <SelectPicker
                                            cleanable={false}
                                            data={element?.affiliation_place_id ? allFieldDropdown?.affilation_place_data?.map((e) => { return { value: e?.id, label: e?.name } }) : affilationDropdown?.place}
                                            placeholder="Select Place"
                                            value={element?.affiliation_place_id}
                                            onChange={(e) => handleOnChange(e, index, -1, "affiliation_place_id")}
                                            block
                                            className={`${(!element?.affiliation_place_id && validated) && "rs-novalidated"}`}
                                            // open={false}
                                            open={element?.affiliation_place_id ? false : undefined} />
                                        {/* {(!affiliatiPlace && validated) && <div className="rs-invalid-feedback">Select Place</div>} */}
                                    </Col>
                                    <div>
                                        {userPermission["Affilation-Program"]?.add == 1 && <Button variant="primary" onClick={() => handleSubPLaceAdd(index)} size="sm" className="btn-icon-lg"><i className='bx bx-plus'></i></Button>}
                                        {userPermission["Affilation-Program"]?.delete == 1 && <Button variant="danger" size="sm" className="btn-icon-lg ms-3" onClick={() => handleDelete(index, element?.data?.map(el => el?.id))}><i className='bx bx-x'></i></Button>}
                                    </div>
                                </div>
                            </Card.Header>
                            <Form id="affiliation" noValidate validated={validated} >
                                {element?.data?.length > 0 && element?.data?.map((ele, ind) => (<Col sm={12}>
                                    <Card>
                                        <Card.Body className="border-bottom">
                                            <Row>
                                                <Col lg={3} md={4}>
                                                    <Form.Label>Services Provider</Form.Label>
                                                    <SelectPicker
                                                        cleanable={false}
                                                        data={affilationDropdown?.serviceProvider}
                                                        block
                                                        onChange={(e) => handleOnChange(e, index, ind, 'service_provider_id')}
                                                        value={ele?.service_provider_id}
                                                        className={`my-2 ${(!ele?.service_provider_id && validated) && "rs-novalidated"}`}
                                                        placeholder="Select Services Provider"
                                                    />
                                                    {/* {(!el?.service_provider_id && validated) && <div className='rs-invalid-feedback'>
                                                Select Service Provider
                                            </div>} */}
                                                </Col>

                                                <Col lg={3} md={4}>
                                                    <Form.Label>Ad Type</Form.Label>
                                                    <SelectPicker
                                                        cleanable={false}
                                                        data={affilationDropdown?.adType}
                                                        block
                                                        value={ele?.ad_type_id}
                                                        onChange={(e) => handleOnChange(e, index, ind, 'ad_type_id')}
                                                        className={`my-2 customMulSelect ${(!ele?.ad_type_id && validated) && "rs-novalidated"}`}
                                                        placeholder="Select Ad Type"
                                                    />
                                                    {/* {(!el?.ad_type_id && validated) && <div className='rs-invalid-feedback'>
                                                Select Ad Type
                                            </div>} */}
                                                </Col>
                                                <Col lg={3} md={4}>
                                                    <Form.Label>UTM Term</Form.Label>
                                                    <CreatableSelect isClearable options={affilationDropdown?.utmTerm}
                                                        onChange={(e) => handleOnChange(e.value, index, ind, 'utm_term')}
                                                        value={{ label: ele?.utm_term, value: ele?.utm_term }}
                                                        // defaultValue={{ label: ele.utm_term, value: ele.utm_term }}
                                                        placeholder="Select UTM Term"
                                                        className={`customMulSelect my-2 ${(!ele?.utm_term && validated) && "rs-novalidated"}`}
                                                        classNamePrefix="react-select"
                                                    />
                                                    {/* {(!el?.utm_term && validated) && <div className='rs-invalid-feedback'>
                                                Select UTM Term
                                            </div>} */}
                                                </Col>
                                                <Col lg={3} md={4}>
                                                    <Form.Label>Lable</Form.Label>
                                                    <SelectPicker
                                                        cleanable={false}
                                                        data={affilationDropdown?.lable}
                                                        block
                                                        value={ele?.lable}
                                                        onChange={(e) => handleOnChange(e, index, ind, 'lable')}
                                                        className={`my-2 ${(!ele?.lable && validated) && "rs-novalidated"}`}
                                                        menuClassName="menu-custom wv-200"
                                                        placeholder="Select Lable"
                                                    />
                                                    {/* {(!el?.lable && validated) && <div className='rs-invalid-feedback'>
                                                Select Lable
                                            </div>} */}
                                                </Col>
                                                <Col lg={3} md={4}>
                                                    <Form.Label>URL</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="title"
                                                        value={ele?.url}
                                                        onChange={(e) => handleOnChange(e.target.value?.trimStart(), index, ind, 'url')}
                                                        className="my-2"
                                                        required
                                                        placeholder='Enter URL'
                                                    />
                                                </Col>
                                                {/* <Col lg={3} md={4}>
                                                    <Form.Label>Banner</Form.Label> */}

                                                {ele?.ad_type_id ? <Col lg={3} md={4} className="align-self-end">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <Button variant={`${validated&&!ele.affiliation_banner_image?"danger":"primary"}`} onClick={() => getImages(index, ind, ele?.ad_type_id, ele.affiliation_banner_image, "is_other")}>Select Image</Button>

                                                        <Fancybox>
                                                            <a href={ele?.get_affilation_banner_image} data-fancybox="gallery">
                                                                <img src={ele?.get_affilation_banner_image} className="hv-40 img-fluid rounded-3 ms-3" alt="" />
                                                            </a>
                                                        </Fancybox>
                                                    </div>
                                                </Col> : <></>}
                                                <Col lg={1} md={4}>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Check type="switch" className="my-2"
                                                        onChange={(e) => handleOnChange(e.target.checked, index, ind, 'status')}
                                                        checked={ele?.status == 1} />
                                                </Col>

                                                <Col lg={1} md={4}>
                                                    <Button onClick={() => deleteSubPlace(index, ind, ele?.id)} variant="danger" size="sm" className="btn-icon-lg ms-3"><i className='bx bx-x'></i></Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>))}
                            </Form>
                        </Card>
                    </Col>
                ))}

            </Row>
            <AffiliationImageModal
                imageshow={imageshow}
                ImagehandleClose={ImagehandleClose}
                handleSelectImage={handleSelectImage}
                selectedModalImage={selectedModalImage}
                setSelectedModalImage={setSelectedModalImage}
                languageImage={languageImage}
                adType={affilationDropdown?.adType || []}
            />
        </>
    )
}

export default SubplaceAdd