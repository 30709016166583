import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import { CheckPicker, Loader } from "rsuite";
import SelectPicker from "rsuite/SelectPicker";
import {
  getServiceProviderDropdown
} from "../service/apis";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
const keyarray = ["vehicle_age_range", "expiry_days_range", "expired_days_range", "maker", "model", "city", "pincode", "state", "manufuring_year", "previous_insurer", "rtoCode"].map((item) => ({
  label: item,
  value: item,
}));
const rsdata = ["InsuranceDekho", "Acko", "Godigit"].map((item) => ({
  label: item,
  value: item,
}));
const rsoptions = [
  { value: "High", label: "High" },
  { value: "Medium", label: "Medium" },
  { value: "Low", label: "Low" },
];
const vehicleTypeDropdown = [
  { value: "Private", label: "Private" },
  { value: "Commercial", label: "Commercial" },
]

const date = new Date();
const CurrentYear = date.getFullYear();
let manufactureYear = [];
for (let i = 1990; i <= Number(CurrentYear); i++) {
  const item = { value: i.toString(), label: i.toString() };
  manufactureYear.push(item);
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  margin: `0 ${grid}px 0 0`,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  padding: `${grid}px 0`,
  width: itemsLength * 166.44 + 16,
});

function AddAffilation({ handleTestOnChange, modalDropDown, makerDropDown, allAffilationData, handleDelete, validated, setAllAffilationData, isSubmited }) {

  const [selectedStates, setSelectedStates] = useState({});
  const [serviceProviderDropdownCity, setServiceProviderDropdownCity] = useState([]);

  const fetchServiceProviderDropdown = async () => {
    try {
      const response = await getServiceProviderDropdown();
      setServiceProviderDropdownCity(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const serviceProvider = serviceProviderDropdownCity?.service_provider?.map(
    (item) => ({ label: item?.provider, value: item?.id })
  );

  const service = serviceProviderDropdownCity?.affilation_service?.map(
    (item) => ({ label: item?.services, value: item?.id })
  );

  const citys = serviceProviderDropdownCity?.affilation_city?.map((item) => ({
    label: item?.city,
    value: item?.id,
    state: item?.state
  }));

  const states = serviceProviderDropdownCity?.affilation_state?.map((item) => {
    return {
      label: item.state,
      value: item.state,
    }
  })


  useEffect(() => {
    fetchServiceProviderDropdown();
  }, []);


  const handleStateChange = (e, i, oldState) => {
    const stateData = e?.map((s) => s.value.toLowerCase()) || [];
    let existedCity = allAffilationData[i]?.city_id_list
      ?.split(',')
      ?.map(Number) || [];

    if (stateData.length > oldState.length) {
      const newStates = stateData.filter(
        (state) => !oldState.some((old) => old?.value?.toLowerCase() === state)
      );

      newStates.forEach((newState) => {
        const matchingCities = citys
          .filter((cityObj) => cityObj?.state?.toLowerCase() === newState)
          .map((cityObj) => cityObj?.value);

        existedCity = [...existedCity, ...matchingCities];
      });
    } else {
      // Removing states
      const removedState = oldState.find(
        (old, index) => old?.value?.toLowerCase() !== stateData[index]
      )?.value?.toLowerCase();

      if (removedState) {
        const removeStateCities = citys
          .filter((cityObj) => cityObj?.state?.toLowerCase() === removedState)
          .map((cityObj) => cityObj?.value);

        existedCity = existedCity.filter(
          (city) => !removeStateCities.includes(city)
        );
      } else if (stateData.length === 0) {
        existedCity = [];
      }
    }

    setSelectedStates((prev) => ({
      ...prev,
      [i]: e,
    }));

    setAllAffilationData((prev) =>
      prev?.map((ele, ind) =>
        ind === i
          ? { ...ele, city_id_list: existedCity.join(',') }
          : ele
      )
    );
  };
  //Delete Range
  const handleDeleteRange = (index, ind, field) => {
    setAllAffilationData((prev) => {
      return prev.map((affiliation, i) => {
        if (affiliation[field]?.split(",")?.length == 1) {
          return {
            ...affiliation,
            [field]: '-',
          };
        }
        if (i === index) {
          return {
            ...affiliation,
            [field]: affiliation[field]?.split(",")?.filter((e) => e?.includes("-") || e?.includes("+"))?.filter((_, inde) => inde !== ind)?.toString() || '-',
          };
        }
        return affiliation;
      });
    });
  }
  //Add Range
  const handleAddRange = (index, field) => {
    setAllAffilationData((prev) => {
      return prev.map((affiliation, i) => {
        if (i === index) {
          const range = [...affiliation[field]?.split(","), "-"]?.toString()?.replaceAll("-,-", "-")?.replaceAll(",,", ",")
          return {
            ...affiliation,
            [field]: range.startsWith(',') ? range.slice(1) : range,
          };
        }
        return affiliation;
      });
    });
  }

  const handleDragEnd = (e, keys, index) => {
    if (!e.destination) return;
    let tempData = Array.from(keys);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    handleTestOnChange(tempData?.toString(), index, "key_priority")
  };
  return (
    <>
      {allAffilationData?.length > 0 &&
        allAffilationData?.map((el, i) => {
          let selectedState = selectedStates[i] || [];
          const allCityId = el?.city_id_list?.split(",");

          if (citys) {
            selectedState = [...new Set(citys?.filter(item => allCityId?.includes(item?.value?.toString()))?.map(item => item?.state))].map((item) => ({ label: item, value: item }));
          }
          const keys = el?.key_priority?.split(",")
          // el?.key_priority?.split(",")?.length != keyarray?.length && handleTestOnChange(keyarray?.toString(), i, "key_priority")
          return (
            <Form id="affiliation" key={i} noValidate validated={validated}>
              <Col sm={12}>
                <Card className="border">
                  <Card.Body>
                    <Row>
                      <Col lg={3} md={4}>
                        <Form.Label>Services Provider</Form.Label>
                        <SelectPicker
                          cleanable={false}
                          data={serviceProvider}
                          block
                          value={el?.service_provider || ''}
                          onChange={(e) =>
                            handleTestOnChange(e, i, "service_provider")
                          }
                          className={`my-2 ${!el?.service_provider && validated && 'rs-novalidated'}`}
                          placeholder="Select Services Provider"
                        />
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                          type="text"
                          name="priority"
                          cleanable={false}
                          data={rsoptions}
                          block
                          value={el?.priority}
                          onChange={(e) => {
                            if (/^[0-9]+$/.test(e.target.value) || !e.target.value) {
                              handleTestOnChange(e.target.value, i, "priority")
                            }
                          }}
                          isInvalid={(+el?.priority > allAffilationData?.length || el?.priority == "0") && validated}
                          className={`my-2`}
                          required
                          placeholder="Select Priority"
                        />
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Manufacturing Year</Form.Label>
                        <Select
                          cleanable={false}
                          options={manufactureYear}
                          value={manufactureYear?.filter((e) => el?.manufuring_year?.split(",")?.includes(e.value?.toString()))}
                          onChange={(e) => handleTestOnChange(e, i, "manufuring_year")}
                          block
                          isMulti
                          className={`customMulSelect my-2`}
                          classNamePrefix="react-select"
                          placeholder="Select Manufacturing Year"
                        />
                      </Col>
                      <Col lg={2} md={4}>
                        <Form.Label>Status</Form.Label>
                        <Form.Check type="switch" className="my-2" checked={el?.status == 1} onChange={(e) => handleTestOnChange(e.target.checked, i, 'status')} />
                      </Col>
                      <Col lg={1} md={4} className="mt-4 text-end">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            handleDelete(
                              i,
                              el?.id && el?.id,
                              el?.new_id && el?.new_id
                            )
                          }
                          className="btn-icon-lg ms-3"
                        >
                          <i className="bx bx-x"></i>
                        </Button>
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Maker</Form.Label>
                        <CheckPicker
                          data={makerDropDown}
                          value={el?.maker?.split(",")?.map((e) => { return +e })}
                          block
                          onChange={(e) => handleTestOnChange(e?.toString(), i, "maker")}
                          className="my-2"
                          placeholder="Select Vehicle Maker"
                        />
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Modal</Form.Label>
                        <CheckPicker
                          placeholder="Select Vehicle Modal"
                          data={modalDropDown}
                          virtualized
                          block
                          className="my-2"
                          value={el?.model?.split(",")?.map((e) => { return +e })}
                          onChange={(e) => handleTestOnChange(e?.toString(), i, "model")}
                        />
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Previous Insurance</Form.Label>
                        <Select
                          cleanable={false}
                          options={rsdata}
                          value={rsdata?.filter((e) => el?.previous_insurer?.split(",")?.includes(e.value?.toString()))}
                          onChange={(e) => handleTestOnChange(e, i, "previous_insurer")}
                          block
                          isMulti
                          className={`customMulSelect my-2`}
                          classNamePrefix="react-select"
                          placeholder="Select Manufacturing Year"
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Label>Pincode List</Form.Label>
                        <Form.Control
                          onChange={(e) => handleTestOnChange(e.target.value, i, "blocked_pincode")}
                          value={el?.blocked_pincode || ''}
                          type="text"
                          className="my-2"
                        />
                      </Col>
                      {/* <Col lg={3} md={4}>
                        <Form.Label className="me-2">City Name</Form.Label>
                        <Form.Check
                          inline
                          label="All City"
                          name="cityname"
                          type="radio"
                          id="city1"
                          checked={el?.city_id_list && el?.city_id_list?.toString()?.toLowerCase() == "all"}
                          onChange={(e) => handleTestOnChange(e.target.checked, i, "is_allCity")}
                        />
                        <Form.Check
                          inline
                          label="Specify City"
                          name="cityname"
                          type="radio"
                          id="city2"
                          checked={el?.city_id_list?.toString()?.toLowerCase() != "all"}
                          onChange={(e) => handleTestOnChange(e.target.checked, i, "is_specify_city")}
                        />
                        {el?.city_id_list?.toString()?.toLowerCase() != "all" && (
                          <>
                            <Select
                              closeMenuOnSelect={false}
                              name="state_name"
                              isClearable={true}
                              placeholder="Select State"
                              className={`customMulSelect my-2`}
                              // onChange={(e) => handleServiceChange(e, i, "state_list")}
                              onChange={(e) => handleStateChange(e, i, selectedState)}
                              classNamePrefix="react-select"
                              isMulti
                              // value={el?.city_id_list?.toString()?.toLowerCase() && el?.city_id_list?.toString()?.toLowerCase() != "all" && citys?.filter((e) =>
                              //   el?.city_id_list?.split(',')?.some((id) => id == e.value)
                              // )}
                              value={selectedState}
                              options={states}
                            />
                            <Select
                              closeMenuOnSelect={false}
                              name="city_name"
                              isClearable={true}
                              placeholder="Select City"
                              className={`customMulSelect my-2 ${el?.city_id_list?.toString()?.toLowerCase() != "all" && el?.city_id_list?.toString()?.toLowerCase()?.length <= 0 &&
                                validated && "rs-novalidated"}`}
                              onChange={(e) => handleTestOnChange(e, i, "city_id_list")}
                              classNamePrefix="react-select"
                              isMulti
                              value={el?.city_id_list?.toString()?.toLowerCase() && el?.city_id_list?.toString()?.toLowerCase() != "all" && citys?.filter((e) =>
                                el?.city_id_list?.toString()?.split(',')?.some((id) => id == e.value)
                              )}
                              options={citys}
                            />
                            <div className="my-2">
                              <Form.Label>Block Pincode List</Form.Label>
                              <Form.Control
                                onChange={(e) => handleTestOnChange(e.target.value, i, "blocked_pincode")}
                                value={el?.blocked_pincode || ''}
                                type="text"
                                className="my-2"
                              />
                            </div>
                          </>
                        )}
                      </Col> */}
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Age </Form.Label>
                        <Row className="g-0">
                          {el?.vehicle_age_range?.split(",")?.filter((e) => e?.includes("-") || e?.includes("+"))?.map((item, index) => {
                            return (
                              <Col md={12} className="d-flex align-self-center">
                                <InputGroup className="my-2">
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Range"
                                    value={
                                      item?.split("-")[0] || ""
                                    }
                                    isInvalid={(validated && !item?.split("-")[0] && !!item?.split("-")[1])}
                                    required={(validated && !item?.split("-")[0] && !!item?.split("-")[1])}
                                    onChange={(e) => {
                                      if (/^[0-9+]+$/.test(e.target.value) || !e.target.value) {
                                        handleTestOnChange(
                                          e.target.value,
                                          i,
                                          "vehicle_age_range",
                                          "range",
                                          1,
                                          index
                                        )
                                      }
                                    }}
                                  />
                                  <InputGroup.Text>-</InputGroup.Text>
                                  <Form.Control
                                    type="text" name="title"
                                    placeholder="Range"
                                    value={item?.split("-")[1] || ""}
                                    onChange={(e) => {
                                      if (/^\d*$/.test(e.target.value)) {
                                        handleTestOnChange(e.target.value, i, "vehicle_age_range", "range", 2, index)
                                      }
                                    }
                                    }
                                    isInvalid={
                                      (item?.split("-")[0] && !item?.split("-")[1]) && validated
                                      || (item?.replace("-", "") && Number(item?.split("-")[0]) >= Number(item?.split("-")[1])) && validated
                                    }
                                    required={(item?.split("-")[0] && !item?.split("-")[1]) && validated
                                      || (item?.replace("-", "") && Number(item?.split("-")[0]) >= Number(item?.split("-")[1])) && validated
                                    }
                                    disabled={item?.split("-")[0]?.includes('+')}
                                  />
                                </InputGroup>
                                <div className="d-flex my-2">
                                  <Button variant="danger" size="sm" className="btn-icon-lg ms-3"
                                    onClick={() =>
                                      handleDeleteRange(
                                        i,
                                        index,
                                        "vehicle_age_range"
                                      )
                                    }
                                  >
                                    <i className="bx bx-x"></i>
                                  </Button>
                                </div>
                              </Col>)
                          })}
                          {!el?.vehicle_age_range?.includes('+') && <Col md={12} className="d-flex justify-content-end">
                            <Button size="sm"
                              onClick={() =>
                                handleAddRange(
                                  i,
                                  "vehicle_age_range"
                                )
                              }
                              className="btn-icon-text my-2"
                            >
                              <i className="bx bx-plus"></i> Add
                            </Button>
                          </Col>}
                        </Row>
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Expiry Days </Form.Label>
                        <Row className="g-0">
                          {el?.expiry_days_range?.split(",")?.filter((e) => e?.includes("-") || e?.includes("+"))?.map((item, index) => {
                            return (
                              <Col md={12} className="d-flex align-self-center">
                                <InputGroup className="my-2">
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Range"
                                    value={
                                      item?.split("-")[0] || ""
                                    }
                                    isInvalid={(validated && !item?.split("-")[0] && !!item?.split("-")[1])}
                                    required={(validated && !item?.split("-")[0] && !!item?.split("-")[1])}
                                    onChange={(e) => {
                                      if (/^[0-9+]+$/.test(e.target.value) || !e.target.value) {
                                        handleTestOnChange(
                                          e.target.value,
                                          i,
                                          "expiry_days_range",
                                          "range",
                                          1,
                                          index
                                        )
                                      }
                                    }
                                    }
                                  />
                                  <InputGroup.Text>-</InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Range"
                                    value={
                                      item?.split("-")[1] || ""
                                    }
                                    onChange={(e) => {
                                      if (/^\d*$/.test(e.target.value)) {
                                        handleTestOnChange(
                                          e.target.value,
                                          i,
                                          "expiry_days_range",
                                          "range",
                                          2,
                                          index
                                        )
                                      }
                                    }
                                    }
                                    isInvalid={(item?.split("-")[0] && !item?.split("-")[1]) && validated || (item?.replace("-", "") && Number(item?.split("-")[0]) >= Number(item?.split("-")[1])) && validated}
                                    required={(item?.split("-")[0] && !item?.split("-")[1]) && validated || (item?.replace("-", "") && Number(item?.split("-")[0]) >= Number(item?.split("-")[1])) && validated}
                                    disabled={item?.split("-")[0]?.includes('+')}
                                  />
                                </InputGroup>
                                <div className="d-flex my-2">
                                  <Button variant="danger" size="sm" className="btn-icon-lg ms-3"
                                    onClick={() =>
                                      handleDeleteRange(
                                        i,
                                        index,
                                        "expiry_days_range"
                                      )
                                    }
                                  >
                                    <i className="bx bx-x"></i>
                                  </Button>
                                </div>
                              </Col>)
                          })}
                          {!el?.expiry_days_range?.includes('+') && <Col md={12} className="d-flex justify-content-end">
                            <Button size="sm"
                              onClick={() =>
                                handleAddRange(
                                  i,
                                  "expiry_days_range"
                                )
                              }
                              className="btn-icon-text my-2"
                            >
                              <i className="bx bx-plus"></i> Add
                            </Button>
                          </Col>}
                        </Row>
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Expired Days</Form.Label>
                        <Row className="g-0">
                          {el?.expired_days_range?.split(",")?.filter((e) => e?.includes("-") || e?.includes("+"))?.map((item, index) => {
                            return (
                              <Col md={12} className="d-flex align-self-center">
                                <InputGroup className="my-2">
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Range"
                                    value={
                                      item?.split("-")[0] || ""
                                    }
                                    onChange={(e) => {
                                      if (/^[0-9+]+$/.test(e.target.value) || !e.target.value) {
                                        handleTestOnChange(
                                          e.target.value,
                                          i,
                                          "expired_days_range",
                                          "range",
                                          1,
                                          index
                                        )
                                      }
                                    }
                                    }
                                    isInvalid={(validated && !item?.split("-")[0] && !!item?.split("-")[1])}
                                    required={(validated && !item?.split("-")[0] && !!item?.split("-")[1])}
                                  />
                                  <InputGroup.Text>-</InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Range"
                                    value={
                                      item?.split("-")[1] || ""
                                    }
                                    onChange={(e) => {
                                      if (/^\d*$/.test(e.target.value)) {
                                        handleTestOnChange(
                                          e.target.value,
                                          i,
                                          "expired_days_range",
                                          "range",
                                          2,
                                          index
                                        )
                                      }
                                    }
                                    }
                                    isInvalid={(item?.split("-")[0] && !item?.split("-")[1]) && validated || (item?.replace("-", "") && Number(item?.split("-")[0]) >= Number(item?.split("-")[1])) && validated}
                                    required={(item?.split("-")[0] && !item?.split("-")[1]) && validated || (item?.replace("-", "") && Number(item?.split("-")[0]) >= Number(item?.split("-")[1])) && validated}
                                    disabled={item?.split("-")[0]?.includes('+')}
                                  />
                                </InputGroup>
                                <div className="d-flex my-2">
                                  <Button variant="danger" size="sm" className="btn-icon-lg ms-3"
                                    onClick={() =>
                                      handleDeleteRange(
                                        i,
                                        index,
                                        "expired_days_range"
                                      )
                                    }
                                  >
                                    <i className="bx bx-x"></i>
                                  </Button>
                                </div>
                              </Col>)
                          })}
                          {!el?.expired_days_range?.includes('+') && <Col md={12} className="d-flex justify-content-end">
                            <Button size="sm"
                              onClick={() =>
                                handleAddRange(
                                  i,
                                  "expired_days_range"
                                )
                              }
                              className="btn-icon-text my-2"
                            >
                              <i className="bx bx-plus"></i> Add
                            </Button>
                          </Col>}
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Form.Label>RTO List</Form.Label>
                        <Form.Control
                          onChange={(e) => handleTestOnChange(e.target.value, i, "rto_code")}
                          value={el?.rto_code || ''}
                          type="text"
                          className="my-2"
                        />
                      </Col>

                      <Col lg={3} md={4}>
                        <Form.Label>Keys </Form.Label>
                        <Select
                          cleanable={false}
                          options={keyarray}
                          block
                          value={el?.key_priority && keyarray?.filter((e) => el?.key_priority?.split(",")?.some((id) => id == e.value))}
                          onChange={(e) => handleTestOnChange(e?.map(item => item.value)?.join(',') || '', i, "key_priority")}
                          isMulti
                          className={`customMulSelect my-2 `}
                          classNamePrefix="react-select"
                          placeholder="Select Vehicle Type"
                        />
                      </Col>
                      <Col md={9}>
                        <Form.Label>Key Priority</Form.Label>
                        <div className="dragrow">
                          <DragDropContext onDragEnd={(e) => handleDragEnd(e, keys, i)}>
                            <Droppable droppableId="droppable" direction="horizontal">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver, keys?.length)}
                                  {...provided.droppableProps}
                                >
                                  {keys?.length > 0 && keys?.map((item, index) => (
                                    <Draggable key={index} draggableId={index?.toString()} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          {item}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      </Col>

                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Form>
          );
        })}
    </>
  );
}

export default AddAffilation;
