import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { updateGroupPositionAffilation, updatePositionAffilation } from '../../service/apis';
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';
import '@dotlottie/react-player/dist/index.css';
import AffiliationViewDrag from './AffiliationViewDrag';

const AffiliationGroupDrag = ({ programViewData, selectedPlace, placeName, parentCallback, affilationDataList, affilateId, selectedCategory }) => {
    const { userPermission } = useContext(AuthContext)
    const [tasks, setTasks] = useState(programViewData)
    useEffect(() => {
        programViewData && setTasks(programViewData)
    }, [programViewData])
    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setTasks(items);
        let id = []
        items?.map((item) => (
            id.push({ group_id: item.group_id })
        ))
        await updateGroupPositionAffilation(selectedPlace, id, selectedCategory).then(() => {
            parentCallback()
            toast.success("Position Changes Successfully")
        }
        )
    };
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="tasks">
                {(provided) => (
                    <Row className='g-4' {...provided.droppableProps} ref={provided.innerRef}>
                        {tasks?.map((item, index) => {
                            return (<Draggable key={index} isDragDisabled={userPermission["Affilation-Program"]?.edit != 1} draggableId={index.toString()} index={index}>
                                {(provided) => (
                                    <Col sm={12} ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <Card className="border">
                                            <Card.Header className="border-bottom d-flex align-items-center justify-content-between">
                                                <h5 className="m-0 fw-bold">{item?.get_name?.category ? item?.get_name?.category : `Group-${item?.group_id}`}</h5>
                                                {userPermission["Affilation-Program"]?.edit == 1 && <Link className="btn btn-primary btn-icon" to="/affiliation/edit"
                                                    state={{
                                                        placed: selectedPlace,
                                                        category: item?.service_category_id,
                                                    }}
                                                ><i className="bx bx-pencil"></i></Link>}
                                            </Card.Header>
                                            <Card.Body>
                                                <AffiliationViewDrag programViewData={item?.affilation_data_list} placeName={programViewData?.place_name} parentCallback={() => parentCallback()} affilationDataList={programViewData?.data} affilateId={item?.id} />
                                            </Card.Body>
                                        </Card>
                                    </Col>)}

                            </Draggable>)
                        })}
                        {provided.placeholder}
                    </Row >
                )}
            </Droppable >
        </DragDropContext >
    );
};

export default AffiliationGroupDrag;
